.personalreviewHistory .card{
    text-align: center;
   position: relative;
   overflow: hidden;
   height: auto;
   margin: 0px;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 10px 20px;
   border: 1px solid rgb(243, 243, 243);
   box-shadow: 0px 0px 12px -4px rgba(184,184,184,0.3);
   cursor: pointer;
   transition: all 0.3s ease;
}
.paymentHistryCssCard .card{

}
.personalreviewHistory .card:hover{
    box-shadow: 0px 0px 12px -4px rgba(184,184,184,0.8);
    transform: scale(1.05);
}
.personalreviewHistory .card-body{
  padding: 10px;
}
.personalreviewHistory .card img{
width: 60px;
height: 60px;
}
.personalreviewHistory .card-title{
   font-size: 12px;
   margin: 0;
   opacity: 0.7;
}
.personalreviewHistory .card-subtitle{
    font-size: 12px;
}
.personalreviewHistory .reviewby{
    position: absolute;
    bottom: 6px;
    left: 10px;
    opacity: 0.7;
    word-break: break-all;
}
.personalreviewHistory .card-text{
    margin-bottom: 15px;
    font-size: 13px;
    opacity: 0.9;
    font-weight: 500;
    word-break: break-all;
}


.paymentHistryCss p{
    word-break: break-word;
    margin: 0;
    font-weight: 500;
    margin-bottom: 5px;
    font-size: 13px;
    opacity: 0.9;
    text-align: left;
}
.reviewqnaorderhistory{
    width: 100%;
    height: 65vh;
    display: flex
;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@media (min-width: 576px) {
    .personalreviewHistory    .card-columns {
      column-count: 2;
    }
  }
  
  @media (min-width: 768px) {
    .personalreviewHistory   .card-columns {
      column-count: 2;
    }
  }
  
  @media (min-width: 992px) {
    .personalreviewHistory    .card-columns {
      column-count: 3;
    }
  }
  
  @media (min-width: 1200px) {
    .personalreviewHistory    .card-columns {
      column-count: 3;
    }
  }
  