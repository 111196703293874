body,html{
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  background: var(--WhiteColor);
  width: 100%;
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
p,h1,h2,h3,h4,h5,h6,a,i{
  padding: 0;
  margin: 0;
}
.w100 {
  font-weight: 100;
}

.w200 {
  font-weight: 200;
}

.w300 {
  font-weight: 300;
}

.w400 {
  font-weight: 400;
}

.w500 {
  font-weight: 500;
}

.w600 {
  font-weight: 600;
}

.w700 {
  font-weight: 700;
}

.w800 {
  font-weight: 800;
}

.w900 {
  font-weight: 900;
}
.btn{
  outline: none !important;
  box-shadow: none !important;
}
.title-section h2 {
  font-size: 20px;
  position: relative;
  font-weight: 700;
  color: var(--TitleColor);
}
.title-section p{
  position: relative;
  width: 250px;
  font-size: 30px;
  margin-top: -10px;
}
.title-row-field h2::after{
  content: '';
  position: absolute;
  width: 70%;
  height: 2.5px;
  border-radius: 10px;
  left: 0px;
  bottom: -13.5px;
  opacity: 0.5;
  background: var(--CommonColor);
}
.slidericonRight .slick-next{
   top: -4.2rem;
   right: 11px;
}
.slidericonRight .slick-prev{
  top: -4.2rem;
  right: 50px;
  left: auto;
}
.slidericonRightTop .slick-next,.slidericonRightTop .slick-prev{
  top: -4.4rem;
}
.title-row-field-border{
  border-bottom: 2px solid rgb(240, 240, 240);
  padding-bottom: 5px;
}
/* .title-section p::before{
  content: '';
  position: absolute;
  width: 100px;
  height: 1.5px;
  right: 0px;
  top: 25px;
  background: #d3d3d3;
} */

.Toastify__toast-container--top-right {
  bottom: var(--toastify-toast-top) !important;
  top: auto !important;
}
:root{
 --WhiteColor:#fff;
 --BlackColor:#000;
 --GrayColor:#5d5d5d;
 --BasicColor:#737373;
 --ratingStar: #faca51;
 --ratingColorNone:#909090;
 --TitleColor:#2a2a2a;
 --SalmonColor:#FFD0D0;
 --CommonColorFont:#333333;
 --GrayColorBtn:#D9D9D9;
 --FontSize10:10px;
 --FontSize11:11px;
 --FontSize12:12px;
 --FontSize14:14px;
 --FontSize13:13px;
 --FontSize16:16px;
 --FontSize18:18px;
 --FontSize28:26px;
 --CommonColor:rgba(203, 28, 30, 1);
 --BtnCommonColor:linear-gradient(90deg, rgba(203, 28, 30, 1) 32%, rgba(231, 44, 34, 1) 79%);;
}



/* Sidebar Container */
.cart-sidebar, .wishlist-sidebar {
  position: fixed;
  top: 0;
  z-index: 2000000001;
  right: 0; /* Keep the sidebar aligned to the right */
  transform: translateX(-500px); /* Start off-screen */
  width: 300px;
  height: 100%;
  background-color: #f6f6f6;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.4s ease-in-out; /* Smooth slide-in/out animation */
  padding: 10px 10px;
}

/* Sidebar Open State */
.cart-sidebar.open, .wishlist-sidebar.open {
  transform: translateX(0); /* Slide into view */
}

/* Sidebar Close Button */

/* .wishlist-sidebar button:hover, */
.cart-sidebar button:hover {
  background-color: #ff3b3b;
}

/* Sidebar Content Styles */
.cart-sidebar .sidebar-content, .wishlist-sidebar .sidebar-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Custom Animations */
@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Applying Animations */
.cart-sidebar.open, .wishlist-sidebar.open {
  animation: slideIn 0.4s ease forwards;
}

.cart-sidebar:not(.open), .wishlist-sidebar:not(.open) {
  animation: slideOut 10s ease forwards;
}


.cartwishHeader{
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cartwishHeader h2{
  font-size: 16px;
}
.cartwishHeader button {
  background: none !important;
  box-shadow: none;
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 18px;
  transition: all 0.3s ease;
  color: rgb(118, 112, 112);
}
.cartwishHeader button:hover{
  opacity: 0.8;
}

.emptyCart{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: 0 0;
  top: 50%;
  transform: translateY(-50px);
  bottom: 0;
  right: 0;
  left: 0;
  height: 98px;
}
.emptyCart img{
  width: 100px;
  height: 75px;
}
.emptyCart p{
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 0px;
  color: #a6a5a5;
}

.productListCart {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: calc(calc(100vh - 160px));
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 10px !important;
}
.productListCart li{
  border-bottom: 1px solid rgb(230, 230, 230);
  padding-top: 10px;
  padding-bottom: 10px;
}
.productListCart li:last-child {
 border-bottom: none !important;
}
.productListCart .productNameCart{

  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  font-size: 13px;
  color: #000;
  font-weight: 600;
 
  
}
.productListCart .productPriceCart{
  font-size: 13px;
    color: var(--CommonColor);
    font-weight: 600;
}
.productListCart .productImgSectionCart{
  background: #fff;
  height: 50px;
  width: 50px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.productListCart .productImgSectionCart img{
  height: 40px;
  width: 40px;
  
}
.productListCart .removeProductCart{
position: absolute;
border: none;
outline: none;
/*top: 0px;
 background: none !important;
color: #a7a7a7; */
transition: all 0.3s ease;
right: 0;
bottom: 5px;
font-size: 20px;
background: none !important;
color: var(--CommonColor);
z-index: 2;
}
.productListCart .removeProductCart:hover{
  opacity: 0.7;
}
.productListCart .quantity-controls button{
    border: 1px solid var(--CommonColor);
    outline: none;
    padding: 0px 5px;
    font-size: 16px;
    border-radius: 6px;
    width: 22px;
    height: 22px;
    line-height: 1;
}
.productListCart .quantity-controls button:hover { color: #fff;}
.productListCart .productCartInfo {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.productCartButton{
  position: fixed;
  bottom: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  left: 0px;
  background: #f6f6f6;
  justify-content: space-between;
  cursor: pointer;
  flex-direction: column;
  gap: 3px;
  z-index: 10;
}
.productCartButton .btn-sm {
  height: 30px;
  background: rgb(189 185 185) !important;
  color: #424141;
  font-size: 13px;
  transition: all 0.3s ease;
  font-weight: 500;
  border-radius: 100px;
  border: none !important;
}
.productCartButton .btn-sm:hover{
  opacity: 0.7;
  
}
.productCartButton .total-price{
  font-size: 14px;
  width: 55%;
  height: 40px;
  font-weight: 500;
  /* background: rgb(107, 87, 239); */
  background: #fff;
  border: 2px solid rgb(233, 233, 233);
  border-left: none;
  border-right: none;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--GrayColor);
  cursor: auto;
  margin-bottom: 5px;
}
.productCartButton .viewcartAndEmptyCart{
  width: 90%;
}
.productCartButton .checkoutCart{
  margin-bottom: 5px;border-radius: 100px;
  background: rgb(33, 0, 246);
}
.productCartButton .checkoutCart p{
    height: 30px;
  background: rgb(246, 140, 30);
  border-radius: 0px;
  display: flex;
  font-size: 13px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  color: #fff;

}
.productCartButton .checkoutCart p:hover a{
  opacity: 0.7;
}
.productCartButton .checkoutCart p a{
  text-decoration: none;
 font-weight: 500;
 display: flex
;
    align-items: center;
    justify-content: center;
  color: #fff;  transition: all 0.3s ease;
}
.modal.show .modal-dialog {
  transform: none;
 min-width: 90%;
}

.outOfStockLabel {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: red;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
}
.out-of-stock{
  position: relative;
 user-select: none;
}
.out-of-stock:hover .productsThumb .productImg{
  transform: scale(1) !important;
}

.out-of-stock:after{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.587);
  left: 0;
  top: 0;
}

 .productComparenavbar .searchbar-navbar.navbar{
  display: none;
 }
 .css-19bb58m input{
  border: none;
  opacity: 0 !important;
 }
/* Scrollbar Styling */
.scrollbar::-webkit-scrollbar {
  width: 8px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.scrollbar::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #ccc; 
}
.productCardItem {
  flex-direction: row;
  align-items: center;
  padding:10px;
  border-bottom: 1px solid #ccc !important;
  background: none;
}
.productCardItem > img {
  width: 55px;
  min-width: 55px;
}
.productCardItem .card-title.h5 {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0;
}
.productCardItem .card-text{
  font-size: 13px;
  font-weight: 600;
  color: var(--CommonColor);
}
.productCardItem .wishListProductCartbtn{
 
}
.productCardItem .btn {
  font-size: 11px;
  color: var(--WhiteColor);
}
.productCardItem .wishListProductCartbtn {
  background: var(--GrayColorBtn) !important;
  border: none;
  color: var(--CommonColorFont);
  border-radius: 0px;
  font-weight: 500;
  transition: all 0.3s ease;
}
.productCardItem .wishListProductCartbtn:hover{
  opacity: 0.7;
}
.productCardItem .removeFromWish{
  border: none;
  font-size: 18px;
  padding: 0px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none !important;
  color: var(--CommonColor);
}
.productCardItem .removeFromWish svg:nth-child(1){
  display: none;
}

.btn-gaps { gap:10px}
.productCardItem .card-body {
  padding: 0 0 0 10px;
}
.scrollbarWishlist {
  max-height: calc(calc(100vh - 100px));
  overflow: auto;
}

.container-full-width {
  max-width: 100%;
}
.pageTitleArea {
  padding: 10px 20px;
}
.pageTitleAreaWrap {
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgba(179, 179, 179, 0.3) !important; */
}
.pageTitleAreaWrap h1 {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin: 0;
  text-transform: uppercase;
}
.pageTitleAreaWrap ul,
.pageTitleAreaWrap li {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #333; 
  text-transform: uppercase;
}
.pageTitleAreaWrap li:last-child > a {
  color: #D52B28;
}
.pageTitleAreaWrap ul { gap: 5px;}
.pageTitleAreaWrap li a {color: #333; text-decoration: none;}
.breadcrumb-list li span { padding-right: 5px;}

.pageTitleAreaWrap li svg{
  position: relative;
  top: -2px;
}
.alreadyHAveAnAccount .toggle-button {color: #D52B28 !important;}