
.nav-side-menu .nav-parent .sub-menu li a.active{
    background: var(--subMenuBg);
    color: var(--commonColor);
  }
  .productdetails-mainslider .nav-tabs{
    border-bottom: 1px solid #cdcdcd;
    margin-top: 5px;
    width: fit-content;
  }
  .productdetails-mainslider .nav-tabs li {
    margin-right: 30px;
   
  }
  .productdetails-mainslider .nav-tabs li button:hover{
    color: var(--CommonColor);
    border-bottom: 2px solid var(--CommonColor);
    opacity: 0.6;
  }
  .productdetails-mainslider .nav-tabs li button {
    color: var(--GrayColor);
    font-size: 14px;
    border: none; 
    transition: all 0.3s ease;
    opacity: 1;
    padding: 5px 3px;
    font-weight: 600;
    border-bottom: 2px solid transparent;
    position: relative;
    margin: 0;
  }
  .productdetails-mainslider .nav-tabs li button::after{
    content: '';
    position: absolute;
    right: -15px;
    background: rgb(199, 199, 199);
    height: 17px;
   width: 1.5px;
   top: 8px;
  }
  .productdetails-mainslider .nav-tabs li button.active {
    border-bottom: 2px solid var(--CommonColor);
    color: var(--CommonColor);;
    opacity: 1 !important;
  }



.productdetails-mainslider {
    margin-top: 20px;
}
.productINfoDetails{
    line-height: 32px;
}

  .productInfoName{
    font-size: 22px;
    font-weight: 600;
  }

  .productInfoRating{
    margin-top: 5px;
    font-size: 14px;
    color: var(--GrayColor);
  }

  .productInfoFinalPrice{
    background: #f1f1f1;
    width: fit-content;
    padding: 10px 15px;
    margin-top: 10px;
    margin-bottom: 5px;
    line-height: 30px;
  }
  .productInfoFinalPrice span:nth-child(1){
    font-size: 14px;
    font-weight: 600;
  }
  .productInfoFinalPrice span:nth-child(2){
    font-size: 20px;
    font-weight: 600;
  }

  .productInfoRegular{
    padding: 10px 0px;
   
    line-height: 30px;
  }
  .productInfoRegular span:nth-child(1){
    font-size: 14px;
    font-weight: 500;
  }
  .productInfoRegular span:nth-child(2){
    font-size: 20px;
    font-weight: 500;
  }
 .productInfoDeleveryTime{
    font-size: 14px;
    color: var(--GrayColor);
 }

 
 .product-actions.productInfoAddToCart .quantity-controls {
    display: flex;
    border: 2px solid #F2F2F2;
    border-radius: 0px;
    width: fit-content;
    margin-bottom: 10px;
    height: 35px !important;
    overflow: hidden;
    box-shadow:none;
  }
  
  .product-actions.productInfoAddToCart .quantity-controls button {
    background-color: #F2F2F2;
    color: #212529;
    font-weight: bold;
    border: none;
    cursor: pointer;
    font-size: 18px;
    width: 30px;
    height: 35px ;
    text-align: center;
    transition: background-color 0.2s;
  }
  .product-actions.productInfoAddToCart .quantity-controls button svg{
    width: 40px;
  }
  .product-actions.productInfoAddToCart .quantity-controls button:hover {
    background-color: #fff;
    color: var(--CommonColor);
  }
  .product-actions.productInfoAddToCart .quantity-controls .input-box {
    width: 25px;
    text-align: center;
    border: none;
    padding: 0px 5px;
    font-size: 14px;
    outline: none;
    font-weight: 600;
    color: #606b74;
  }
  
  /* Hide the number input spin buttons */
  .product-actions.productInfoAddToCart .quantity-controls .input-box::-webkit-inner-spin-button,
  .product-actions.productInfoAddToCart .quantity-controls .input-box::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .product-actions.productInfoAddToCart .quantity-controls .input-box[type="number"] {
    -moz-appearance: textfield;
  }
  
  .quickOverView h4{
    font-size: 16px;
    font-weight: 600;
    margin-top: 8px;
    margin-bottom: 5px;
  }
  .quickOverView ul {
    padding: 0px;
    padding-left: 20px;
  }
  .quickOverView ul li{
    font-size: 14px;
    padding: 0;
    margin: 0;
    line-height: 24px;
  }
  .product-actions.productInfoAddToCart button{
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--CommonColor);
    outline: none;
    font-size: 14px;
    font-weight: 600;
    box-shadow: none;
    height: 35px;
    border: 2px solid var(--CommonColor);
    padding: 2px 10px;
    color: #fff;
    gap: 5px;
    transition: all 0.3s ease;
  }
  .product-actions.productInfoAddToCart button:hover{
    background: #fff;
    color: var(--CommonColor);
  }
.btn-group-sop .btn{
 font-size: 13px;
 background: #F2F2F2;
 outline: none;
 color: #212529;
 border: none;
 box-shadow: none;
 border-radius: 4px;
 transition: all 0.3s ease;
}
.btn-group-sop .btn:hover{
  background: var(--CommonColor);
  color: #fff;
}
.spec-item h3{
  font-size: 15px;
  margin-bottom: 5px;
  font-weight: 600;
  color: rgb(66 66 66);
  margin-top: 10px;

}
.spec-item  p{
  font-size: 14px;
  color: rgb(96 96 96);
}


.iiz__img,.iiz{
  height: 450px;
  width: 100%;
}

.product-attribute tr th,.product-attribute tr td{
  font-size: 14px;
  text-align: center;
  color: var(--GrayColor);
  border: 1px solid rgb(240, 240, 240);
}
.product-attribute tr td{
  font-size: 13px;
   text-align: left;
  border: 1px solid rgb(240, 240, 240);
}
.downloaded-documents {
 position: relative;
 width: 100%;
 margin-top: 0px;
}
h5.sepecTitle{
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}
.downloaded-documents .document-cards{
 display: flex;
 flex-wrap: wrap;
 gap: 10px;

}
.downloaded-documents .document-card{
  border: 1px solid rgb(236, 236, 236);
  overflow: hidden;
  padding: 10px;
  position: relative;

  width: 200px;
  
}

.downloaded-documents .document-card p{
  word-break: break-all;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
font-size: 13px;
text-overflow: ellipsis;
}
.downloaded-documents .document-card p strong{
  color: var(--GrayColor);
  font-weight: 500;
  
}

.downloaded-documents .document-card button{
  font-size: 12px;
  padding: 5px 20px;
  margin-top: 10px;
  border-radius: 0px;
  border: none !important;
  outline: none !important;
  transition: all 0.3s ease;
  background: rgb(92, 179, 125) !important;
}
.downloaded-documents .document-card button:hover{
  opacity: 0.7;
}

.specifications-content table{
  width: auto !important;
}


.callwppforprice .modal-title{
  font-size: 18px;
  color: var(--GrayColor);
}
.callwppforprice .modal-body p{
  margin-top: 10px;
}
.callwppforprice .modal-body p:nth-child(2) a{
  color: rgb(246, 140, 30);
}
.callwppforprice .modal-body a{
  font-size: 17px;
  transition: all 0.3s ease;
  text-decoration: none;
  color: #5cb37d ;
}
.callwppforprice .modal-body a:hover{
  opacity: 0.6;
}

.callwppforprice.modal.show .modal-dialog{
  min-width: 312px !important;
  
}