.table.table-border th,
.table.table-border td {
    /* border: 1px solid #ccc; */
    vertical-align: middle; 
    padding: 15px 5px;
}
.table-responsive .table.table-border {
    min-width: 500px;
}
.table.table-border tbody tr th,
.table.table-border tbody tr td,
.table.table-border thead tr th {
    background-color: #ffffff;
}



.btn-del {
    padding: 0;
    outline: none !important; 
    border: none !important;
}
.btn-del svg {
    width: 20px;
    height: 20px;
}

.product-add-col {
    max-width: 86px;
}
.product-add-col small {
    line-height: 1;
}
.product-add-col svg { cursor: pointer;}

.checkoutPageSection  .boxCenter{margin: 0 auto;
border-right: 1px solid rgba(204, 203, 204, 0.5);
padding: 20px;
border-radius: 0px;
background-color: #fff;
box-shadow: none !important;
}



.ContinueShopping a {
    text-decoration: none;
}

.checkoutTitle,.ordersummeryHeaderTitle{
    font-size: 20px;
    font-weight: 600;
}
.checkoutPageSection .chekoutUserDetails .inputUser input{
    border: 1px solid #e7e7e7 !important;
    border-radius: 4px !important;
}
.checkoutPageSection .chekoutUserDetails .inputUser input,.newAddressModal .inputUser input,.remarkInstructions textarea{
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    padding: 0.4rem 0.5rem;
    font-size: 13px;
    box-shadow: none !important;

}
.checkoutPageSection .chekoutUserDetails .inputUser label,.newAddressModal .inputUser label ,.selectUser label{
    font-weight: 500;
    color: #495057;
    font-size: 14px;
    margin-bottom: 0.5rem;
}
.checkoutPageSection .chekoutUserDetails .inputUser input:hover,.newAddressModal .inputUser input:hover,.remarkInstructions textarea:hover {
    border: 1px solid #9fc1e9;
}
.bellingAddressAndDeleveryAddress  .addressAddTitle{
  text-align: left;
  font-weight: 600;
  position: relative;
  z-index: 0;
}

.bellingAddressAndDeleveryAddress .or::after {
   
    top: 13px;
}
.bellingAddressAndDeleveryAddress .or span {
    font-size: 14px;
    padding: 0 10px 0px 0px;
}

.bellingAddressAndDeleveryAddress  .addressAddTitle button{
    padding: 0px;
    z-index: 90;
    position: relative;
    margin: 0px;
    background: #fff !important;
    outline: none !important;
    box-shadow: none !important;
    border: none;
    font-size: 20px;
    color:rgb(246,140,30) ;
    transition: all 0.3s ease;
}
.bellingAddressAndDeleveryAddress  .addressAddTitle button:hover{
    color:rgba(246,140,30,0.6) ;
}


 .selectUser .css-b62m3t-container, .newAddressModalContainer .css-3iigni-container{
    font-size: 13px;
   
    
}
/* .selectUser .css-1nmdiq5-menu{
  height: 150px ;
  background: red;
  overflow-y:auto;
  display: block !important;
} */
.Select__menu-list {
    max-height: 120px !important;
  }




.selectUser  .css-13cymwt-control,.selectUser   .css-t3ipsp-control,.newAddressModalContainer .css-16xfy0z-control{
border: 1px solid #e7e7e7;
min-height: 23px !important;
height: 33px !important;
    padding-top: 0px;
    align-items: baseline;
    box-shadow: none;
    cursor: pointer;
}
.selectUser   .css-1wy0on6 div{
    padding: 6px !important;
}

.selectUser   .css-b62m3t-container .css-t3ipsp-control:hover,.selectUser   .css-13cymwt-control:hover{
    border: 1px solid #9fc1e9;
}

.selectedAddress .selectedAddressBlock h6{
    font-weight: 500;
    color: #495057;
    font-size: 14px;
    margin-bottom: 0.5rem;
}
.selectedAddress .selectedAddressBlock p{
    background: rgb(248, 248, 248);
    padding: 10px;
    border-radius: 10px;
    font-size: 13px;
}

.boxCenter  .placeorderbutton{
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  border: none;
  box-shadow: none;
  outline: none;
  background: rgb(246,140,30);
  color: #fff;
  padding: 8px;
  transition: all 0.3s ease;
  border-radius: 5px;
}
.boxCenter  .placeorderbutton:hover{
    opacity: 0.7;
}


.newAddressModal{
    position: fixed;
    /* height: 85%; */
    z-index: 9999;
    top: 50%;
    background: #fff;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    width: 50%;
    border-radius: 10px;
    box-shadow: 0px 0px 36px -10px rgba(179,179,179,1);
}
.newAddressModalContainer{
 height: 85%;
 padding: 0px;
 overflow-y: auto;
}
.addressbarFooter{
    margin-top: 10px;
}
.addressbarFooter .btn {
  font-size: 14px;
  padding: 4px 10px;
  border-radius: 2px;
  border: none !important;
  box-shadow: none !important;
  transition: all 0.3s ease;
}
.addressbarFooter .btn:nth-child(3){
    background: var(--CommonColor);
    margin-right: 10px;
    
}
.addressbarFooter .btn:hover{
    opacity: 0.7;
}
.addressbarFooter .btn:nth-child(2),.addressbarFooter .btn:nth-child(1){
    background: rgb(246,140,30);
    margin-right: 10px;
    
}
.addtingNewAddressHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.addtingNewAddressHeader .btn{
  background: none !important;
  box-shadow: none;
  border-radius: 0px;
  outline: none;
  margin-top: -10px;
  margin-right: -9px;
  border: none;
  color: #495057;
  padding: 5px;

}



.newAddressModalContainer::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .newAddressModalContainer::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .newAddressModalContainer::-webkit-scrollbar-thumb {
    background: #e2e2e2; 
  }
  
  /* Handle on hover */
  .newAddressModalContainer::-webkit-scrollbar-thumb:hover {
    background: #cacaca; 
  }
.addtingNewAddressTitle{
    font-size: 16px;
    margin-bottom: 10px;
}

.remarkInstructions textarea{

}


.payemntOptionOptions .radio-buttons {
    display: flex;
    gap: 15px;
    flex-direction: row;
    align-items: center;
    border: none;
    justify-content: start;
  }
 
.payemntOptionOptions .radio-buttons 
{

}

.payemntOptionOptions .custom-radio input 
{
  display: none;
}

.payemntOptionOptions .radio-btn 
{
    /* overflow: hidden; */
    padding: 0;
    padding: 0px 10px;
    display: flex;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    position: relative;
    text-align: center;
    /* box-shadow: 0 0 20px #c3c3c367; */
    cursor: pointer;
    height: 45px;
}

.payemntOptionOptions .radio-btn > i {
    color: #ffffff;
    background-color: #9de799;
    font-size: 15px;
    position: absolute;
    top: 14px;
    left: 0px;
    transform: translateX(-50%) scale(2);
    border-radius: 100px;
    padding: 0px;
    padding-top: 3px;
    padding-left: 2px;
    transition: 0.5s;
    pointer-events: none;
    opacity: 0;
    height: 20px;
    width: 20px;
    display: flex
;
    align-items: center;
}

.payemntOptionOptions .radio-btn .hobbies-icon 
{
 display: flex;
 align-items: center;
 justify-content: center;
}
.payemntOptionOptions .radio-btn .hobbies-icon img
{
  
  width: 25px;
  height: 25px;
  margin-right: 5px;
  
}
.payemntOptionOptions .radio-btn .hobbies-icon i 
{
  color: #FFDAE9;
  line-height: 80px;
  font-size: 60px;
}

.payemntOptionOptions .radio-btn .hobbies-icon h3 
{
  color: #555;
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize;
}

.payemntOptionOptions .custom-radio input:checked + .radio-btn 
{
    background: rgba(246,140,30,0.2);
  border: 1px solid #FFDAE9;
}

.payemntOptionOptions .custom-radio input:checked + .radio-btn > i 
{
  opacity: 1;
  transform: translateX(-50%) scale(1);
}


.order-summary{
    padding-top: 20px;
}
.oderSummeryList{
 list-style-type: none;
 padding: 0;
 margin: 0;
 display: flex;
 flex-direction: column;
 gap: 0px;
}
.oderSummeryList li{
  list-style-type: none;
  display: flex;
  position: relative;
  gap: 10px;
  padding: 16px 0px;
  align-items: center;
  border-bottom: 1px solid rgb(240, 240, 240);
}
.oderSummeryList li img{
    width: 30px;
    height: 30px;
    border-radius: 5px;
}
.oderSummeryList li p:nth-child(1) {
    position: relative;
}
.oderSummeryList li p:nth-child(1) span{
    display: block;
    position: absolute;
    background: #dbdbdb;
    font-size: 12px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    overflow: hidden;
    border-radius: 100px;
    top: -10px;
    right: -5px;
}

.oderSummeryList li p:nth-child(3){
    position: absolute;
    right: 0px;
}
.oderSummeryList li p{
    font-size: 13px;
    color: #495057;
    font-weight: 500;
}
.oderSummeryList li p:nth-child(2){
    width: 80px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}


.order-summary .accordion-button{
  box-shadow: none !important;
  /* border: 1px solid #e7e7e7; */
    font-size: 14px;
    color: #495057;
    padding: 8px 12px;
}
.order-summary .accordion-body{
    padding-top: 5px;
}
.order-summary .accordion-header{
    font-size: 14px;
    color: #495057;
}
.order-summary .accordion-button::after{
  opacity: 0.5;
  transform: scale(0.8);
}



.oderTotalPRicethings{
    padding: 0;
    margin: 0;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.oderTotalPRicethings li{
    list-style-type: none;
    display: flex;
   
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.oderTotalPRicethings li p{
    font-size: 13px;
    color: #495057;
    font-weight: 600;
}
.grandTotal{
   
    margin-top: 6px;
    font-size: 14px;
    background:linear-gradient(90deg, rgba(203, 28, 30, 1) 32%, rgba(231, 44, 34, 1) 79%);
    width: 100%;
    text-align: center;
    color: #fff;
    padding: 5px 10px;
    display: flex;
    text-transform: capitalize;
    font-weight: 600;
    border-radius: 2px;
    justify-content: space-between;

}




.weeAcceptCard{
    padding: 0;
    margin: 0;
}
.weeAcceptCard h6{
    font-size: 14px;
}
.weeAcceptCard ul{
    display: flex;
    gap: 20px; 
    padding: 0;
    margin: 0;
}
.weeAcceptCard  li{
    list-style-type: none;
    display: flex;
    width: 25%;
    padding: 10px;
    justify-content: center;
    border: 1px solid rgb(241, 241, 241);
    
}
.weeAcceptCard ul li  img{
    width:100%;
    height: 25px;
}




.partial-payment-driven{
 display: flex;
 gap: 10px;
 margin-top: 20px;
 margin-bottom: 5px;
 padding-top: 15px;
 justify-content: start;
 border-top: 1px solid rgb(206, 206, 206);
}

.partial-payment-options .form-check-input {
    box-shadow: none !important;
    border: 1px solid rgb(218, 218, 218);
    cursor: pointer;
}
.partial-payment-options label {
    color: var(--GrayColor);
    font-size: 15px;
    cursor: pointer;
}
.partial-payment-options .form-check-input:checked {
    background-color: var(--CommonColor);
    border-color: var(--CommonColor);
}

.partial-payment-percentage{
    text-align: center;
    margin-top: 15px;
    display: flex;
    gap: 5px;
    flex-direction: column;
    
}
.partial-payment-percentage label{
  display: flex;
  align-items: center;
  
}
.partial-payment-percentage label span{
    position: absolute;
    margin-left: 10px;
   
}
.partial-payment-percentage p{
    color: rgb(106, 177, 112) ;
    font-size: 13px;
    text-align: start;
}
.partial-payment-percentage input{
    outline: none;
  
    font-weight: 600;
    border-left: none !important;
    border-right: none !important;;
    padding-left: 30px !important;
    width: 100%;
    padding:6px 4px;
    text-align: left;
    border: 1px solid rgb(214, 214, 214);
}

.partial-payment-percentage input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }