#week-countdown{
   margin-top: 0px;
    position: relative;
}
.weekCountdown{
    position: relative;
}
.weekCountdown div{
   position: relative;
   width: 55px;
   height:38px;
   border-radius: 5px;
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: center;
   border: 1px solid var(--CommonColor);
   
}
.weekCountdown p:nth-child(1){
    color: var(--CommonColor);
    font-weight: 700;
    border-radius: 5px;
    font-size: 12px;
    padding: 0;
    margin: 0px;
    width: fit-content;
}
.weekCountdown p:nth-child(2){
   
    color: #b9b9b9;
    border-radius: 100px;
    font-size: 11px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 0px;
    padding: 0;
    margin-top: -5px;
    width: fit-content;
}