/* Container and Headings */
.ratingReview h3,.ratingReview h4 {

    color: #343a40;
  }
  
  .ratingReview  h3 {
    font-size: 1.1rem;
    margin-bottom: 0.8rem;
  }
  
  .ratingReview h4 {
    font-size: 1rem;
    margin: 0.2rem 0 1rem;
  }
  
  .ratingReview #stars {
    margin-bottom: 1rem;
  }
  
  /* Form Styles */
  .ratingReview .reviewfromLeave {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  }
  
  .ratingReview .reviewfromLeave label {
    font-weight: 500;
    color: #495057;
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
  .ratingReview .reviewfromLeave .form-check-label{
    font-size: 12px;
  }
  .ratingReview .form-control {
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    padding: 0.4rem 0.5rem;
    font-size: 13px;
    box-shadow: none;
  }
  .ratingReview .form-control:hover, .ratingReview .form-control:focus{
    border: 1px solid #9fc1e9;
  }
  .ratingReview textarea.form-control {
    resize: none;
  }
  
  .ratingReview button[type="submit"] {
    background: linear-gradient(90deg, rgba(203, 28, 30, 1) 32%, rgba(231, 44, 34, 1) 79%);
    color: #fff;
    padding: 0.4rem 1.5rem;
    border: none;
    font-size: 14px;
    margin-top: 10px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .ratingReview button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  /* Star Rating */
  .ratingReview label input {
    display: none;
  }
  
  .ratingReview label .fa-star {
    transition: color 0.2s ease-in-out;
  }
  
  .ratingReview .fa-star {
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .ratingReview .fa-star:hover {
    transform: scale(1.2);
  }
  
  .ratingReview .fa-star.active {
    color: #ffc107;
  }
  
  /* Reviews Section */
  .ratingReview .list-unstyled {
    padding-left: 0;
    margin-bottom: 1.5rem;
  }
  .ratingReview .list-unstyled{
     display: flex;
    gap: 5px;
  }
  .avarageRating .list-unstyled li {
    background: transparent;
    padding: 0rem;
    font-size: 1.6rem;
    border-radius: 0px;
    margin-bottom:5px;
  
  }
  .avarageRating .avarageRatingPoint {
    font-size: 20px;
    color: #262727;
    font-size: 32px;
    font-weight: 500;
    padding: 0;
    margin: 0;
  }
  .avarageRating .avarageRatingPoint span{
    font-size: 20px !important;
    color: #6c757d;
  }
.avarageRating .avarageRatingMan{
  font-size: 14px;
  color: #6c757d
}
  .avarageRating .list-unstyled li strong {
   
    color: #007bff;
  }
  
  .avarageRating .list-unstyled li span {
    font-size: 0.9rem;
    color: #6c757d;
    margin-left: 0.5rem;
  }
  
  .avarageRating .list-unstyled li p {
    font-size: 0.95rem;
    color: #495057;
    margin-top: 0.5rem;
  }
  
  /* Modal Styles */
  .ratingReview .modal-content {
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
  
  .ratingReview .modal-header {
    background-color: #007bff;
    color: #fff;
    border-bottom: none;
  }
  
  .ratingReview .modal-title {
    font-size: 1.3rem;
  }
  
  .ratingReview .modal-body {
    font-size: 1rem;
    color: #495057;
  }
  
  .ratingReview .modal-footer button {
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem 1.2rem;
  }
  
  .ratingReview .modal-footer button:hover {
    background-color: #0056b3;
  }


   .avarageRatingProgressBar{
    /* box-shadow: 1px 2px 5px rgb(240, 240, 240); */
    padding:20px 30px 20px 30px ;
    border-radius: 20px;
  } 
  .ratingProgressBar .progress-bar{
    background: var(--ratingStar);
    border-radius: 0px;
  }
  .ratingProgressBar .progress{
    border-radius: 0px;
    height: 0.85rem;
    font-size: 10px;
  }
.ratingProgressBar .star-label{
  display: flex;
  align-items: center;
  color: var(--ratingStar);
  font-size: 13px;
  gap: 2px;
  width: 32px;
}
.ratingProgressBar .star-label span{
  width: 10px;
  color: #6c757d
}

.filtered-reviews .review-item{
   border-top: 1px solid rgb(245, 245, 245);
   padding-top: 15px;
   padding-bottom: 15px;
   padding-left: 10px;
}
.filtered-reviews .review-item:nth-child(1){
 border-top: none;
}
.filtered-reviews .review-item p{
  font-size: 14px;
  color: #262727;
  margin-top: 10px;
}

.filtered-reviews .review-item li {
  background: transparent;
  padding: 0rem;
  font-size: 10px;
}
.filtered-reviews .review-item  strong{
  font-size: 10px;
  color: #6c757d
}


.product-Review-rating h6{
  font-size: 14px;
}
.sort-dropdown label{
  font-size: 14px;
  padding: 0;
  margin: 0;
}
.sort-dropdown .form-control{
  border: none !important;
  box-shadow: none !important;
  padding: 3px 5px;
    cursor: pointer;
}
.product-Review-rating{
  margin-top: 0px;
  border-top: 1px solid rgb(245, 245, 245);
  border-bottom: 1px solid rgb(245, 245, 245);
}
.product-Review-rating .dropdown-menu.show {

  transform: translate(0px, 40px) !important;
}
.product-Review-rating .dropdown-toggle{
  font-size: 12px;
  border-left: 1px solid rgb(245, 245, 245) !important;
  background: none !important;
  padding: 10px 5px 10px 8px;
  border-radius: 0px;
  border-right: none !important;
  border-top: none !important;
  border-bottom: none !important;
  box-shadow: none !important;
  color: #3a3a3a !important;
  font-weight: 500;
}
.product-Review-rating .dropdown-toggle svg{
  font-size: 12px;
  color: #818181 !important;
}
.product-Review-rating .dropdown-toggle span{
   color: #6c757d !important;
   font-weight: 500;
}
.product-Review-rating .dropdown-item{
  font-size: 12px;
  font-weight: 500;
  color: #3a3a3a  !important;
}
.product-Review-rating .dropdown-item.active{
  color: #3a3a3a !important;

  background: #b3b3b323 !important;
}
.product-Review-rating .dropdown-item:hover,.product-Review-rating .dropdown-item:focus{
  background: #b3b3b323 !important;
}
.product-Review-rating .dropdown-menu{
  box-shadow: 0px 4px 7px 0px rgba(176, 167,  176, 0.15);
}
.custom-pagination-rq{
  display: flex;
    align-items: center;
    justify-content: end;
}
.custom-pagination-rq .page-item.active .page-link{
  background: #d1d1d1;
  border-color: #d1d1d1;
}
.custom-pagination-rq .page-item .page-link{
  color: #3a3a3a ;
  border-color: #ebebeb;
  border-radius: 0px !important;
  font-size: 12px;
  box-shadow: none !important;
  outline: none !important;
}
.reviewfromLeave .star-rating{
 margin-top: -8px;
}
.reviewfromLeave .star-rating label{
  cursor: pointer;
}