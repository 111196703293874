.orderhistory{
    border: 1px solid rgb(245, 245, 245);
    margin-top: 15px;
    padding: 15px 15px;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    border-bottom: 5px solid rgba(246, 142, 30, 0.295);
    box-shadow: 0px -3px 18px -6px rgba(212,208,212,0.4);
}
.orderhistory .order_id_status h5{
  font-size: 14px;
  font-weight: 600;
}

.orderhistory .order_id_status .confirmed{

  color:var(--CommonColor)  ;
}
.orderhistory .order_id_status .allsda{
   color:  rgb(106, 177, 112);
}
.orderhistory .order_id_status .allsda,.orderhistory .order_id_status .confirmed{
  font-size: 15px;
  font-weight: 500;
   
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  .orderhistory .order_id_status .allsda p{
     background: rgb(106, 177, 112);
  }
  .orderhistory .order_id_status .confirmed p{
    background:var(--CommonColor) ;
  }
  .orderhistory .order_id_status .allsda p, .orderhistory .order_id_status .confirmed p{
    color: #fff;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    height: 30px;
    font-size: 13px;
  }
.orderhistory .ordertypeall{
    font-size: 14px;
    line-height: 25px;
    font-weight: 500;
    color: rgb(80, 80, 80);
}
.orderhistory .ordertypeall span{
    color: rgb(120, 120, 120);
}
.orderhistory .accordion-item:first-of-type>.accordion-header .accordion-button{
    padding: 6px 8px;
    font-size: 14px;
    color: rgb(120, 120, 120);
    border-radius: 3px;
   box-shadow: none !important;
}
.orderhistory .accordion-button::after{
    opacity: 0.6;
    background-size: 0.9rem;;
    width: 0.9rem;
    height: 0.9rem;
}
.orderhistory .accordion-item{
  border-radius: 3px !important;
}

.orderhistory .yourOrderProdect tr th{
  font-size: 12px;
  color: rgb(120, 120, 120);
}
.orderhistory .yourOrderProdect tr td{
    font-size: 12px;
  color: rgb(120, 120, 120);
}
.orderhistory .accordion-body{
    padding: 10px 10px;
}
.order-cencel-btn{
  position: absolute;
}
.order-cencel-btn .btn{
  font-size: 12px;
  padding: 2px 10px;
  margin-top: 77px;
  margin-left: 12px;
  border-radius: 0px;
  outline: none !important;
  box-shadow: none;
  transition: all 0.3s ease;
  background: rgb(219, 45, 45) !important;
  border: 1px solid rgb(219, 45, 45);;
}
.order-cencel-btn .btn:hover{
  opacity: 0.8;
}

.page-link {
  font-size: 14px;
  color: #727171;
  box-shadow: none !important;
}
.page-item.active .page-link {
  background: var(--CommonColor);
  border: 1px solid var(--CommonColor);
  color: #fff;
}