/* src/css/loadingSpinner.css */
.loading-spinner-container {
    position: fixed;
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 0px;
    width: 100%;
    top: 0px;
    left: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  