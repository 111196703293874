
.filterWrapper {
   
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
 .leftSidebarMenuFilter {
    z-index: 1000;
    position: fixed;
    left: 0px;
    top: 60px;
    width: 270px;
    height: calc(100% - 60px);
    overflow-y: auto;
    background: #ffffff;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .leftSidebarMenuFilter::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Track */
  .leftSidebarMenuFilter::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .leftSidebarMenuFilter::-webkit-scrollbar-thumb {
    background: #dbdbdb; 
  }
  
  /* Handle on hover */
  .leftSidebarMenuFilter::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
 .rightBarProductShowFilter{
    width: 100%;
    padding-left: 270px;
    position: relative;
    
  }

.productAllFilterOption{
    position: relative;
    padding-top: 12px;
}


  

  .product-grid-view .productCard {
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    border: 1px solid rgb(240, 240, 240);
  }
  
  .product-grid-view .productImg img {
    width: 100%;
    height: auto;
  }
  
  /* List view */
  .product-list-view {
    display: flex;
    margin: 0px !important;
  }
  
  .product-list-view .productImg img {
    width: 150px;
    height: auto;
    margin-right: 20px;
  }
  
  .product-list-view .productCaption {
    flex: 1 1;
    min-width: 60% !important;
  }
  
  .product-list-view .productCard {
    flex-direction: row;
    align-items: center;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid #ddd;
  }
  .product-list-view .productCard:hover .productMenuList{
    position: absolute;
    right: 0px;
   bottom: 0px;
  }
  .product-list-view .productCard .productMenuList{
    position: absolute;
    left: 0px;
    top: auto;
    bottom: 0px;
    height: 40px;
  }
  .product-list-view .productCard .productMenuList ul {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin: 0px;
    padding: 0px;
}
.product-list-view .productCard .productMenuList li {
 margin-bottom: 0px;
  border-radius: 0px;
}
.product-list-view .productCard .productPrize {
  flex-direction: column;
  align-items: start !important;
  gap: 3px;
}
.productAllFilterOption{
  padding: 10px ;
}



.catagoryFilter,.priceDivFilter,.brandingFilter,.ratingFilter {
  border: 1px solid rgb(235, 235, 235);
  padding: 15px 10px;
}
/* .catagoryFilter{
  padding: 15px 10px 0px 10px;
} */

.catagoryFilter h4,.priceDivFilter h4,.brandingFilter h4,.ratingFilter h4{
  font-size: 17px;
  text-transform: uppercase;
}









.catagoryFilter .dropdown .dropdown-menu{
  position: relative !important;
  transform: translate3d(0px, 0px, 0px) !important;
  box-shadow: none;
  padding-top: 0px;
  font-size: 14px;
  padding-bottom: 0px;
}
.catagoryFilter .dropdown-menu .dropdown-item{
  padding: 0px;
  background: #fff !important;
  color: var(--GrayColor);
  position: relative;
}
.catagoryFilter .dropdown-menu .dropdown-item span:hover{
  background:#f8f9fa ;
}
.catagoryFilter .allcatagrousf{
 color: var(--GrayColor) !important; 
}
.catagoryFilter .allcatagrousfnone{
  color: var(--CommonColor) !important;
}
.catagoryFilter .allcatagrousf:hover{
  background: none !important;
}
.catagoryFilter #category-dropdown{
  padding-bottom: 0px !important;
}
/* .catagoryFilter .dropdown-menu .dropdown-item{
  background:#f8f9fa ;
} */
.catagoryFilter .dropdown-menu .dropdown-item span{
  position: relative;
  padding: 5px 0px 5px 5px;
}
.allcatagrousf,.allcatagrousfnone{
  padding: 5px 0px 5px 0px !important;
}
.catagoryFilter .dropdown .dropChildItems{
  padding: 5px 5px 5px 8px;
}
.catagoryFilter .dropChildItems.selected {
  color:var(--CommonColor);

}
.catagoryFilter .dropdown .dropChildItems:hover,.allcatagrousf:hover{
  background:#f8f9fa !important;
}
.catagoryFilter .dropParentstems{
  margin-top: 0px;
  
}
.catagoryFilter .dropParentstems {
  padding-left: 10px;
  padding-right: 10px;
}
.catagoryFilter .dropdown-menu .dropdown-item span span{
  font-size: 10px;
  margin-left: 6px;
  top: 6px;
  color: rgb(178, 179, 178);
  position: absolute;
  right: 10px;
}
.catagoryFilter .dropdown-toggle::after{
  display: none;
}
.catagoryFilter .dropdown-toggle{
  width: 100%; 
  border: none !important;
  border-top: 0px solid var(--CommonColor) !important;
  border-bottom: 1px solid var(--CommonColor) !important;
 padding-left: 0px;
  text-align: left;
  background: none !important;
  color: #D52B28;
  font-size: 14px;
  border-radius: 0px;
}
.catagoryFilter .dropdown-toggle span{
  font-size: 10px;
  margin-left: 6px;
  top: 10px;
  color: rgb(178, 179, 178);
  position: absolute;
  right: 10px;
}
.catagoryFilter .dropdown-menu .dropdown-item span{
  font-size: 10px;
  right: 10px;
  color: rgb(178, 179, 178);
}












/* General styling for the slider */
.priceDivFilter .horizontal-slider {
  width: 100%; /* Full width */
  height: 8px; /* Height of the slider track */
  background-color: #ddd; /* Base track color */
  margin: 20px 0;
  border-radius: 5px; /* Rounded edges */
  position: relative;
}

/* Track styling */
.priceDivFilter .slider-track {
  background-color: #e4e4e4; /* Track color when selected */
  height: 8px;
  border-radius: 5px;
}

/* Thumb styling */
.priceDivFilter  .slider-thumb {
  height: 18px;
  width: 18px;
  outline: none;
  background-color: var(--CommonColor); /* Thumb color */
  border-radius: 50%; /* Make the thumb circular */
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Optional shadow for thumb */
  position: relative;
  top: -5px; /* Center thumb on track */
}
.priceDivFilter .slider-track.slider-track-1{
  background: var(--CommonColor);
}
.priceDivFilter  .priceshowDiv{
  color: var(--GrayColor);
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: nowrap;
  white-space: nowrap;
}
.priceDivFilter  .priceshowDiv span{
  border: 1px solid rgb(237, 237, 237);
  padding: 5px 5px ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  text-align: center;
}

.disToTop{

}
.disToTop .form-check .form-check-input,.brandingFilter .form-check .form-check-input{
  box-shadow: none !important;
  border: 1px solid rgb(218, 218, 218);
  cursor: pointer;
}
.disToTop .form-check  label,.brandingFilter .form-check label{
  color: var(--GrayColor);
  font-size: 14px;
  cursor: pointer;
}
.disToTop .form-check-input:checked,.brandingFilter .form-check-input:checked{
  background-color: var(--CommonColor);
  border-color: var(--CommonColor);
}

.ratingFilter p{
  color: var(--GrayColor);
  font-size: 16px;
  margin-top: 2px;
  text-transform: capitalize;
}


.brandingFilter div:nth-child(2){
 
}









.allProductShowing{
  padding: 0px 5px;
}
.topbarFilterOptions{
  margin: 0;
  padding: 0;
  border: 1px solid rgb(235, 235, 235);
  margin-top: 12px;
   padding: 5px 5px;
   z-index: 99;
   background: rgb(255, 255, 255);
   width: 100%;
   margin-bottom: 20px;
   justify-content: space-between;
}

.gridclasicalView button{
  border: none;
  outline: none;
}
.gridclasicalView button:hover{
  color: #fff;
  background: var(--CommonColor) !important;
}

.droptopbarokli .dropdown-toggle{
  background: #f5f5f5 !important;
  color: var(--GrayColor) !important;
  font-size: 14px ;
  padding: 2px 8px;
  border-radius: 0px;
  border: 1px solid rgb(236, 236, 236) !important;
}
.droptopbarokli .dropdown-toggle:hover,.droptopbarokli .dropdown-toggle:focus{
  background: var(--CommonColor) !important;
  color: #fff !important;
}
.droptopbarokli .dropdown .dropdown-item{
  font-size: 13px;
} 

.droptopbarokli  .dropdown-toggle::after{
  opacity: 0.6;
}
.droptopbarokli .dropdown-menu {
  transform: translate(0px, 29px) !important;
}


.displayedProductspagination .page-item.active .page-link{
   background: var(--CommonColor);
   border: 1px solid var(--CommonColor);
   color: #fff;
}
.displayedProductspagination .page-item .page-link{
   font-size: 14px;
   color: #727171;
   box-shadow: none !important;
}

.resetfilter .btn{
  background: var(--CommonColor) !important;
 border: none;
 border-radius: 0px;
 font-size: 13px;
 padding: 3px 8px;
 transition: all 0.3s ease;
}
.resetfilter .btn:hover{
  opacity: 0.7;
}