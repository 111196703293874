
/* .topHeader-navbar{
    display: none;
} */
.topNavbar{
    padding: 10px 0px;
    /* background: var(--SalmonColor); */
    background: #FCFCFC;
    border-bottom: 1px solid rgb(236, 236, 236);
}

.topheader-content p{
    font-size: var(--FontSize12);
    border-right: 1px solid rgb(248, 248, 248);
    padding-right: 5px;
    color: var(--CommonColorFont);
   
}
.topheader-content .needHelp p,.topheader-content  .welcomeTitle p{
 border-right: none;
}
  .topheader-content p:nth-child(even){
      border-right: none;
  }
  
  
  .topheader-content .dropdown-toggle{
    background: none;
    font-size: var(--FontSize12);
    padding: 0px 0px;
    color: #939393;
    border: none;
  }

  @keyframes ring {
    0% {
      transform: rotate(0);
    }
    10% {
      transform: rotate(15deg);
    }
    20% {
      transform: rotate(-15deg);
    }
    30% {
      transform: rotate(10deg);
    }
    40% {
      transform: rotate(-10deg);
    }
    50% {
      transform: rotate(5deg);
    }
    60% {
      transform: rotate(-5deg);
    }
    100% {
      transform: rotate(0);
    }
  }
  
  .ringing {
    display: inline-block;
    animation: ring 1s infinite;
    transform-origin: top center; /* Adjust pivot point for realistic ringing */

  }

  .topNavbar .contactUs{
    padding-left: 5px;
  }
  .topNavbar .contactUs p a{
   text-decoration: none;
   color: var(--CommonColorFont);
   font-weight: 600;
   transition: all 0.3s ease;
  }
  .topNavbar .contactUs p a:hover{
    opacity: 0.5;
  }

  @media screen and (max-width:576px) {
    .topNavbar .contactUs{
       display: none;
      }
      .topheader-content p{
       border: none;
    }
    .welcomeTitle{
        display: none;
    }
    .needHelp  p{
        font-size: 10px;
    }
    .topNavbar {
        padding: 6px 0px;
    }
  }