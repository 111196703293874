.footer{
    background: #1B1B1B;
    padding-top: 100px ;
    margin-top: -40px !important;
    padding-bottom: 40px;
}

.footer h4{
    font-size: 14px;
    font-weight: 400;
    color: var(--WhiteColor);
    margin-bottom: 15px;
}
.footer .footersub1 p{
    color: #c1c1c1;
    font-size: 13px;
    line-height: 26px;
}
.footer .footersub1 p:nth-child(3){
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 0px;
   
}
.footer .footersub1 p:nth-child(3) a{
     color: #c1c1c1 ;
     font-weight: 600;
     transition: all 0.3s ease;
}
.footer .footersub1 p:nth-child(3) a:hover{
    opacity: 0.7;
}
.footer .footersuball a ,.footer .footersuball span{
    color: #c1c1c1;
    font-size: 13px;
    cursor: pointer;
    text-decoration: none;
    line-height: 30px;
    transition: all 0.3s ease;
}
.footer .footersuball a:hover,.footer .footersuball span:hover{
    color: var(--CommonColor);
}
.footer .footersub4 img{
    width: 100%;
    height: 100%;
}



.paymentoptionList{
 background: #323232;   
}
.paymentoptionList .card{
    background: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 0px;
    padding: 10px 0px;
}
.paymentoptionList .payulske{
    color: #c1c1c1;
    font-size: 14px;
    font-weight: 500;
}

.paymentoptionList .card .allpaymentCard ul{
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.allpaymentCard img{
    width: 100%;
    height: 60px;
    
}