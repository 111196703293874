
.wrapperAlert {
    width: 400px;
    height: 450px;
    margin: 0 auto;
    overflow: hidden;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    border-radius: 0px;
    border: 0px solid #ddd;           
  }
  
  .wrapperAlert .topHalf {
    width: 100%;
    color: white;
    overflow: hidden;
    min-height: 250px;
    position: relative;
    padding: 40px 0;
    background: rgb(0,0,0);
    background:linear-gradient(90deg, rgba(203, 28, 30, 1) 32%, rgba(231, 44, 34, 1) 79%);
  }
  
  .wrapperAlert .topHalf p {
    margin-bottom: 30px;
    text-align: center;
  }
  .wrapperAlert  svg {
    fill: white;
  }
  .wrapperAlert  .topHalf h1 {
    font-size: 2.25rem;
    display: block;
    text-align: center;
    font-weight: 500;
    letter-spacing: 0.15rem;
    text-shadow: 0 2px rgba(128, 128, 128, 0.6);
  }
          
  /* Original Author of Bubbles Animation -- https://codepen.io/Lewitje/pen/BNNJjo */
  .wrapperAlert  .bg-bubbles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;            
    z-index: 1;
  }
  
  .wrapperAlert  li{
    position: absolute;
    list-style: none;
    display: block;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.15);/* fade(green, 75%);*/
    bottom: -160px;
  
    -webkit-animation: square 20s infinite;
    animation:         square 20s infinite;
  
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
  }
  .wrapperAlert li:nth-child(1){
    left: 10%;
  }		
  .wrapperAlert li:nth-child(2){
    left: 20%;
  
    width: 80px;
    height: 80px;
  
    animation-delay: 2s;
    animation-duration: 17s;
  }		
  .wrapperAlert li:nth-child(3){
    left: 25%;
    animation-delay: 4s;
  }		
  .wrapperAlert li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
  
    animation-duration: 22s;
  
    background-color: rgba(white, 0.3); /* fade(white, 25%); */
  }		
  .wrapperAlert li:nth-child(5){
    left: 70%;
  }		
  .wrapperAlert li:nth-child(6){
    left: 80%;
    width: 120px;
    height: 120px;
  
    animation-delay: 3s;
    background-color: rgba(white, 0.2); /* fade(white, 20%); */
  }		
  .wrapperAlert li:nth-child(7){
    left: 32%;
    width: 160px;
    height: 160px;
  
    animation-delay: 7s;
  }		
  .wrapperAlert li:nth-child(8){
    left: 55%;
    width: 20px;
    height: 20px;
  
    animation-delay: 15s;
    animation-duration: 40s;
  }		
  .wrapperAlert li:nth-child(9){
    left: 25%;
    width: 10px;
    height: 10px;
  
    animation-delay: 2s;
    animation-duration: 40s;
    background-color: rgba(white, 0.3); /*fade(white, 30%);*/
  }		
  .wrapperAlert li:nth-child(10){
    left: 90%;
    width: 160px;
    height: 160px;
  
    animation-delay: 11s;
  }
  
  @-webkit-keyframes square {
    0%   { transform: translateY(0); }
    100% { transform: translateY(-500px) rotate(600deg); }
  }
  @keyframes square {
    0%   { transform: translateY(0); }
    100% { transform: translateY(-500px) rotate(600deg); }
  }
  
  .wrapperAlert .bottomHalf {
    align-items: center;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .wrapperAlert .bottomHalf p {
    font-weight: 500;
    font-size: 1.05rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .wrapperAlert button {
    border: none;
   
    color: white;
    cursor: pointer;
    border-radius: 0px;            
    padding: 10px 18px;            
    background:linear-gradient(90deg, rgba(203, 28, 30, 1) 32%, rgba(231, 44, 34, 1) 79%);
    text-shadow: 0 1px rgba(128, 128, 128, 0.75);
  }
  .wrapperAlert  button:hover {
    background-color: #85ddbf;
  }

  .wrapperAlert button  a{
    text-decoration: none;
    color: #fff;
  }