
.searchbtnclick{
    transition: all 0.3s ease;
}
.searchbtnclick:hover{
    opacity: 0.6;
}
.searchinputresult{
    position: absolute;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    background: #fff;
    -webkit-box-shadow: 0px 0px 9px -2px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 9px -2px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 9px -2px rgba(0,0,0,0.15);
    margin-top: 40px;
}
.searchinputresult::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  .searchinputresult::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .searchinputresult::-webkit-scrollbar-thumb {
    background: #dddddd; 
  }
  
  /* Handle on hover */
  .searchinputresult::-webkit-scrollbar-thumb:hover {
    background: #acacac; 
  }

.search-results {
  
}
.search-results .card{
    border: 1px solid rgb(235, 235, 235);
    border-radius: 0px;
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    padding-left: 10px;
   
}
.search-results .card img{
width: 50px;
height: 50px;
padding: 6px;
}
.search-results .card .card-body{
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 0px;
    gap: 6px;
    border: none;
    transition: all 0.3s ease;
}
.search-results .card .card-body .card-title{
    font-size: 13px;
    color: var(--GrayColor);
    transition: all 0.3s ease;
    padding: 0px;
    margin: 0px;
}

.search-results .card .card-body:hover .card-title{
    color: var(--CommonColor);
}