
.dropdown .dropdown-submenuNav{
    position: absolute;
    right: auto;
    left: 100%;
    margin-top: -30px;
    background: #fff;
    /* width: 100%; */
    overflow: hidden;
    visibility: hidden;
  box-shadow: 3px 2px 9px -3px rgba(176,167,176,0.4);
  
}
.dropdownNAvMenuShow:hover  .dropdown-submenuNav{
    visibility: visible;
}
.dropdownNAvMenuShow .dropdown-parent{
  position: relative;
}
.dropdownNAvMenuShow .arowrightdrop{
    position: absolute;
    top: 3px;
    right: 7px;
    opacity: 0.5;
}
.searchbar-navbar{
    box-shadow: 0px 2px 0px 0px rgba(150,148,150,0.25);
}
.navbar .navbar-toggler{
    padding: 0px;
    border: none;
    box-shadow: none;
    outline: none;
    font-size: 18px;
}

.main-navbar-menu .navbafsd a {
    background: #fff;
    color: #939393 !important;
    border-bottom: 1px solid #efecec;
    border-radius: 0px;
}
.searchbar-navbar  .navbafsd.dropdown .dropdown-menu{
    position: absolute ;
    left: 160px ;
    top: -5px;
    width: 100%;
    padding-top: 0px;
}
.searchbar-navbar .dropdown-menu.show{
    padding-bottom: 0px;
    width: 100%;
    padding-top: 0px;
}
.main-navbar-menu{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
    gap: 18px;
    align-items: center;
}

.searchbar-navbar{
    background: var(--WhiteColor) !important;
    
}
.navbar-brand,.navbar-brand img{
    width: 150px;
}
.search-block .form-control{
    box-shadow: none !important;
    outline: none !important;
    border-radius: 5px 0px 0px 5px;
    border-right: none !important;
}
.search-block .form-control:hover,.search-block .form-control:focus{
    border-color:#a5a5a5;
    
}
.search-block .btn,.search-block .btn:focus{
    background: var(--GrayColorBtn) !important;
    color: var(--BtnCommonColor);
    border-radius: 0px 5px 5px 0px;
    border: 1px solid var(--GrayColorBtn) !important;
}
.searchbar-navbar .dropdown-toggle{
    background: var(--BtnCommonColor);
    font-size: 14px;
    border: none !important;
    outline: none !important;
    box-shadow: none;
    border-radius: 4px;
    padding: 8px 10px;
}

.login-signin-block{
   
    background: var(--GrayColorBtn);
    text-transform: capitalize;
    
    font-size: var(--FontSize13);
    border-radius: 4px;
    transition: all 0.3s ease;
}
.login-signin-block:hover{
    opacity: 0.6;
}
.login-signin-block a{
    display: flex
    ;
        align-items: center;
        gap: 4px;
    text-decoration: none;
    color: var(--CommonColorFont);
    padding: 8px 10px;
}
.login-signin-block p{
    font-size: 18px;
    margin-top: -3px;
    margin-right: 3px;
}

.login-signin-cart{
    white-space: nowrap;
}
.cart-block p{
    position: relative;
    font-size: var(--FontSize28);
    cursor: pointer;
    color: var(--GrayColor);
}
.cart-block p span{
    position: absolute;
    font-size: var(--FontSize10);
    background: var(--BtnCommonColor);
    color: var(--WhiteColor);
    border-radius: 100px;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -2px;
    right: -10px;
    text-align: center;
}




.main-navbar-menu.gap-1.navbar-nav .nav-link:nth-child(2){
    opacity: 0;
    visibility: hidden;
    user-select: none;
}




.main-navbar-menu-all{
   
    background: var(--WhiteColor) !important;
}
.catagory-block .dropdown-toggle::after{
 display: none;
}
.catagory-block .dropdown-toggle{
    background: var(--BtnCommonColor);
    color: var(--WhiteColor) !important;
    border-radius: 5px;
    padding: 5px 10px;
}
.searchbar-navbar .HelplineLanguageCss{
    display: none !important;
}

.main-navbar-menu .nav-link{
    font-size: var(--FontSize14);
    font-weight: 500;
    color: var(--GrayColor);
    display: flex;
    align-items: center;
    gap: 3px;
    transition: all 0.3s ease;
}
.main-navbar-menu .nav-link:hover{
    color: var(--CommonColor);
}

.dropdown-menu {
    animation: 0.5s slideup;
    border: none;
    border-radius: 0px;
    box-shadow: 0px 4px 7px 0px rgba(176,167,176,0.5);
  }
  .dropdown-menu  .dropdown-item{
    border-top: 1px solid rgb(237, 237, 237);
  }
  @keyframes slideup {
    from {
      transform: translateY(10%);
    }
  
    to {
      transform: translateY(0);
    }
  }
  .HelplineLanguageCss .dropdown-toggle{
      display: flex;
  align-items: center;
  }

  .HelplineLanguageCss .dropdown-toggle::after{
    display: none;
   }
.HelplineLanguageCss .dropdown-menu{
    right: 0px !important;
    left: auto !important;
    z-index: 9999 !important;
}



.searchbar-navbar .userdropdownSelection .dropdown-toggle{
  background: none;
  padding: 0px;
}
.searchbar-navbar .userdropdownSelection .dropdown-toggle img{
    width: 35px;
    height: 35px;
    border: 1px solid #d8d8d8;
    border-radius: 100px;
    background-color: rgb(250, 250, 250);
}
.searchbar-navbar .userdropdownSelection .dropdown-menu{
  left: auto;
  right: 0;
  min-width: 185px;
}
.searchbar-navbar .userdropdownSelection .dropdown-toggle::after{
    color: #c1c1c1;
}


.dcUserItems{
    padding: 8px 5px;
   
    text-align: center;
    background: rgb(250, 250, 250) !important;
}
.dcUserItems span{
   font-size: 12px;
}

.dcUserItems .media {
    padding: 3px 5px 2px 10px;
}
.dcUserItems .media  img {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #d8d8d8;
}
.dcUserItems .media .media-body {
    margin-top: 3px;
}
.dcUserItems .media .media-body p {
    /* text-decoration: none;
    color: var(--GrayColor);
    font-size: 12px; */
    padding: 0px;
    font-size: 12px;
    display: flex;
}
.dcUserItems .media .media-body p a {
    text-decoration: none;
    color:#979797;
    font-size: 12px;
}

.dcUserItems .media .media-body h5 {
    font-size: 12px;
    margin-top: 2px !important;
    color:#5e5e5e;
    font-weight: 600;
    text-align: left;
}
.searchbar-navbar  .dropdown-menu .dropdown-item{
    font-size: 14px;
    color:#5e5e5e;
  
  }
  .searchbar-navbar  .dropdown-menu .dropdown-item:hover,.searchbar-navbar  .dropdown-menu .dropdown-item:focus{
    background: #eeeeee
  }

















  @media screen and (max-width:992px) {

.searchbar-navbar .navbar-collapse{
    position: fixed;
    width: 280px;
   

    background: #fff;
    height: 100%;
    top: 0px;
    left: 0px;
}
/* .login-signin-block{
    width: 130px;
} */
.login-signin-cart {
    white-space: pre;
}  

.searchbar-navbar  .navbafsd.dropdown .dropdown-menu{
    position: relative ;
   
}



.searchbar-navbar{
    
    box-shadow: 0px 1px 8px -3px rgba(0,0,0,0.15);
}


.enterSearchBlockMobile .navbar-toggler{
    border: none;
       box-shadow: none;
       padding: 0px;
       font-size: 16px;
       }
   .enterSearchBlockMobile .navbar-collapse{
       position: fixed;
       width: 200px;
       height: 100%;
       left: -300px;
       top: 0px;
       overflow-y: auto;
       background: #fff;
       z-index: 9999;  
       padding: 20px 0px;
       transition: all 0.5s ease;
       box-shadow: 1px 0px 8px -3px rgba(0, 0, 0, 0.15)
   }
   .enterSearchBlockMobile .navbar-collapse.show{
       left: 0px;
       transition: all 0.5s ease;
   }
     
   
        .enterSearchBlockMobile {
           display: flex;
           flex-direction: row-reverse;
           justify-content: space-between;
       } 
   
       .login-signin-block {
           
           font-size: 12px;
           
       }
   
   
   
   .enterSearchBlockMobile .navbar-nav{
       max-height: 100% !important;
      
       height: 100% !important;
       display: block !important;
   }

   .catagory-block .dropdown-toggle,.main-navbar-menu .nav-link{
    border-radius: 0px;
   
    border-bottom: 1px solid rgb(227, 227, 227);
    padding-left: 15px;
}
.dropdown-menu .dropdown-item{
    color: #fff;
    
}
.dropdown-item:focus, .dropdown-item:hover{
    background: var(--CommonColor);
    color: #ebebeb;
}

.catagory-block .dropdown-toggle{
    background: var(--WhiteColor) !important;
    color: var(--GrayColor) !important;
}
.dropdown-menu{
    box-shadow: none;
    padding-top: 0px;
    padding-bottom: 0px;
    background: linear-gradient(90deg, rgba(203, 28, 30, 1) 32%, rgba(231, 44, 34, 1) 79%);;
}
.navbar-expand-sm .navbar-nav .dropdown-menu{
    position: relative !important;
}
.HelplineLanguageCss p{
    border-bottom: 1px solid rgb(227, 227, 227);
    width: 100%;
    padding-top: 7px;
    padding-bottom: 6px;
    padding-left: 15px;
    font-size: 14px;
}

.HelplineLanguageCss .dropdown-toggle::after{
 display: none;
}
.HelplineLanguageCss .dropdown{
    width: 100%;
}
.HelplineLanguageCss .dropdown-toggle {
    background: #fff !important;
    color: var(--GrayColor);
    font-size: 14px;
   border: none;
   border-bottom: 1px solid rgb(227, 227, 227);
   width: 100%;
   text-align: left;
        padding: 0px 0px 8px 15px;
        border-radius: 0px;
}

.HelplineLanguageCss{
    display: flex !important;
    flex-direction: column !important;
    align-items: start !important;
}

.searchbar-navbar .HelplineLanguageCss{
    display: flex !important;
}





    
    .weekdealBgImage img,.weekdealBgImage::after{
        height: 1000px !important;
    }
    .facilities .card{
        border: none !important;
    }
    section.offerProduct{
        margin-top: 60px !important;
    }
    .newsLatter .card{
        display: block !important;
    }
    .newsLatter .scoileApp{
        margin-top: 10px;
    }    
    .newsLatter .fromSubmition {
        margin-top: 10px;
    }
    .hero .slider-container .heroSlide::before{
        width: 120% !important;
    }  
  }
  @media screen and (max-width:768px) {
    .enterSearchBlockMobile .navbar-toggler{
        display: block;
    }
    .main-navbar-menu-all{
        bottom: 0;
        top: auto;
        box-shadow: 0px -1px 8px -3px rgba(0,0,0,0.15);
    }
      .hero .slider-container .heroSlide::before {
      
       width: 150% !important;
    }

   
    
    .navbar-nav.navbar-nav-scroll .nav-link{
        border-bottom: 1px solid rgb(227, 227, 227);
        padding-left: 15px;
   }
   .searchbar-navbar .enterSearchBlockMobile .navbar-toggler{
       display: none;
   }
   .login-signin-block{
    padding: 0px 10px;
    font-size: var(--FontSize13);
    border-radius: 5px;
}
.login-signin-block a{
   
    font-size: 12px !important;
    padding-left: 0px !important;
}
.login-signin-block p{
    font-size: 16px;
    margin-top: -3px;
    margin-top: -3px;
    margin-right: 3px;
}
.userdropdownSelection .dropdown-toggle{
    background: #ececec !important;
    border: none !important;
    border-radius: 100px;
}
.userdropdownSelection .dropdown-toggle img{
 width: 20px;
}

  }

  @media screen and (max-width:576px) {
   

    .mainProductCart{
        padding: 3px;
        padding-bottom: 2px;
        padding-top: 2px;
    }
    .mainProductCartRow .row{
        padding-left: 8px;
        padding-right: 8px;
    }
    .mainProductCartRowAll .row{
        padding-left: 4px;
        padding-right: 4px;
    }
    .hero .slider-container .heroSlide::before {
        left: -245px !important;
        height: 240% !important;
        top: -159px !important;
        width: 122% !important;
        transform: rotate(22deg) !important;
        transform-box: content-box;
    }
    .hero .heroCard{
        padding: 10px 5px !important;
    }


.hero .heroSlide img {
   
    height: 370px !important;

  }
  .hero .heroCard h1{
    font-size: 28px !important;
    line-height: 35px !important;
  }
  .heroCard .sologan{
    font-size: 14px !important;
  }
  .hero .heroCard {
   
    transform: translateY(-50%) !important;
}
.exploreshopBtn{
    margin-top: 18px !important;
}
.freshProduct.slideContent{
    display: none;
}
.exploreshopBtn a {
   
    height: 40px !important;
    
    font-size: 18px !important;
}
.paymentoptionList {
   
    margin-bottom: 50px !important;
}

.paymentoptionList .payulske{
    padding-right: 20px !important;
}

.enterSearchBlockMobile  .catagory-block,.enterSearchBlockMobile  .nav-link
{
   
}



/* .mainProductCartRowAll .nav.nav-tabs{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}
.mainProductCartRowAll .nav.nav-tabs .nav-item{
    white-space: nowrap;
} */

.productCaption {
    height: 150px !important;
}

  }   
  @media screen and (max-width:524px) {
    .weekCountdown {
        gap: 15px !important;
    }
    .weekCountdown p:nth-child(2) {
       
        font-size: 11px !important;
    }
  .weekdeal  .weekCountdown p:nth-child(1) {
    
    width: 55px;
    height: 55px;

    font-size: 18px;
}  
.login-signin-cart{
    gap: 20px !important;
}
.cart-block p{
    font-size: 20px;
}
.cart-block p span{
    width: 16px;
    font-size: 8px;
    height: 16px;
}

.navbar-brand, .navbar-brand img {
    width: 100px;
    margin-top: -3px;
}
.search-block .form-control {
    padding: 3px 10px;
    font-size: 13px;
}
.searchbar-navbar{
    padding: 0px 0px;  
}
.search-block .btn, .search-block .btn:focus{
    height: 30px;
    display: flex;
}
  } 
  @media screen and (max-width:492px) {

  .productCard .productsThumb .productImg img {
    width: 110px !important;
    height: 110px !important;
}
.productCard .productsThumb{
    height: 190px !important;
}
.productCard .productsThumb .productImg {
    margin-top: 25px;
}
.ProductReview .rating-stars li {
    font-size: 12px !important;
}
.ProductReview p {
    font-size: 12px;
}

.productName a h3 {
   
    font-size: 14px !important;
}
.productCaption {
    height: 130px !important;
    padding: 4px 10px !important;
    gap: 3px !important;
}
  }
  @media screen and (max-width:340px) {
.search-block .btn{
    width: 27px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 0px;
}
  }