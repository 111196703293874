.productCard{
    position: relative;
    overflow: hidden;
    border: none;
    border-radius: 0px;
    transition: all 0.5s ease;
}

.productCard .productsThumb{
    height: 235px;
    width: 100%;
    text-align: center;
    background: #ffffff;
    border-bottom:1px solid rgb(240, 240, 240);
}

.productCard .productsThumbLink{
    width: 100%;
    height: 100%;
    z-index: 0;
    position: relative;
    display: flex;
    padding:10px;
    align-items: center;
    justify-content: center;
}
.productCard .productsThumb .productImg{
    transition: all 0.3s ease;
}
.productCard .productsThumb .productImg img{
  width: 140px;
  height: 140px;
}
.productCard:hover .productsThumb .productImg{
  transform: scale(1.2);
}
.productCard .productLabel{
    position: absolute;
    top: 5px;
    z-index: 90;
    left: 5px;
    transition: all 0.5s ease;
}
.productCard .onsale {
    background: var(--CommonColor);
    color: #ffffff;
    border: 1px solid var(--CommonColor);;
    margin-bottom: 5px;
    padding: 2px 8px;
    font-size: 10px;
    border-radius: 10px;
}
.wishlistTopbar{
    position: absolute;
    top: 5px;
    cursor: pointer;
    z-index: 90;
    right: 5px;
    transition: all 0.5s ease; 
}
.wishlistTopbar p{
    cursor: pointer;
    width: 28px;
    font-size: 14px;
    height: 28px;
    border: 1px solid rgb(235, 235, 235);
    background: #ffffff;
    border-radius: 100px;
    display: flex;
    align-items: center;
    color: #979797;
    justify-content: center;
    padding: 2px;
    flex-direction: column;
    margin-top: 6px;
    margin-bottom: 6px;
    transition: all 0.3s ease;
}
.productCard .productMenuList{
    position: absolute;
    top: 40px;
    right: -90px;
    z-index: 90;
}
.productCard .productMenuList ul{
    
}
.productCard .stayColor.added{
    background: var(--CommonColor) !important;
   color: var(--WhiteColor) !important;
}
.productCard .productMenuList li{
    list-style-type: none;
    cursor: pointer;
    width: 28px;
    height: 28px;
    border: 1px solid rgb(235, 235, 235);
    background: #ffffff;
    border-radius: 100px;
    display: flex;
    align-items: center;
    color: #979797;
    justify-content: center;
    padding: 2px;
    flex-direction: column;
    margin-top: 6px;
    margin-bottom: 6px;
    transition: all 0.3s ease;
}
.productCard .productMenuList li:hover {
   background: var(--CommonColor);
   color: var(--WhiteColor);
}

.productCard:hover .productMenuList{
    right: 5px;
    transition: all 0.3s ease;
}

.productCaption{
    height: 120px;
    padding: 10px 15px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
   
    gap: 6px;
}
.ProductReview .rating-stars ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 4px;
    cursor: pointer;
    -moz-user-select: none;
    -webkit-user-select: none;
}
.ProductReview .rating-stars ul > li.star {
    display: inline-block;
}
.ProductReview .rating-stars li{
    font-size: 14px;
}
.ProductReview p{
    font-size: 14px;
    color: var(--ratingColorNone);
}
.productName a{
    text-decoration: none;
    font-size: 22px;

}
.productName h3{
    text-decoration: none;
    font-size: 14px;
   cursor: pointer;
}
.productName  h3{
    color: var(--TitleColor);
    font-size: 17px;
    font-weight: 600;
    transition: all 0.3s ease;
    display: -webkit-box;
    max-width: 400px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.productName  h3:hover{
    color: var(--CommonColor);
}
.productPrize h4{
    color: var(--CommonColor);
    font-size: 16px;
    font-weight: 600;
}

.notshowPriceBtn .btn{
    background: var(--CommonColor) !important;
    border: none !important;
    font-size: 12px;
    padding: 5px 14px;
    border-radius: 0px;
    outline: none !important;
    transition: all 0.3s ease;
}
.notshowPriceBtn .btn:hover{
    opacity: 0.6;
}