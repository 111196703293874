.categories .card{
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border-radius: 10px;
    flex-direction: column;
    text-align: center;
    border: none;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease;
    box-shadow: 0px 3px 10px -2px rgba(0,0,0,0.10);
}
.categories  .card a{
    width: 100%;
    height: 100%;
    display: flex; 
    padding: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.categories .card a img{
    width: 100px;
    height: 100px;

}
.categories .card a h3{
    margin-top: 25px;
    font-size: 18px;
    color: var(--BasicColor);
    text-align: center;
}
.categories .card:hover{
    border-bottom: 2px solid var(--CommonColor);
}
.categories .card:hover a h3{
    color: var(--CommonColor);
}
.categories .card:hover  img { 
    animation: Shake 1s linear ; 
} 
@keyframes Shake { 
    0% { 
        transform: rotate(5deg);  
    } 
  
    20% { 
        transform: rotate(-6deg); 
    } 
  
    40% { 
        transform: rotate(5deg); 
    } 
  
    60% { 
        transform: rotate(-6deg); 
    } 
  
    80% { 
        transform: rotate(5deg); 
    } 
    100%{
        transform: rotate(-6deg); 
    }

  
}




.alltagsofproduct{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 8px;
}
.alltagsofproduct p{
    cursor: pointer;
    font-size: 11px;
    color: var(--CommonColor);
    padding: 4px 8px;
    font-weight:600;
    border-radius: 5px;
    border: 1px solid rgb(199, 199, 199);
    background: #ffffff;
}