.signSectionBlock{
  position: relative;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url('../../public/imgs/wave.svg');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size:cover;
  background-position: 100% 100%;
}
.nextRow {
  padding: 0 5px;
}
.input-container,
.otp-container {
  transition: all 0.5s ease-in-out;
}
.input-container.slide-left {
  transform: translateX(-100%);
  opacity: 0;
  pointer-events: none;
}
.otp-container.slide-in {
  transform: translateX(0);
  opacity: 1;
}
.signMidellContainer {
  padding: 20px 15px;
}
.signMidellContainer h2{
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color:#333;
}
.signMidellContainer form{
  margin-top: 10px;
}
.signMidellContainer .byDefaultCode{
  padding: 8px;
  text-align: center;
  outline: none !important;
  box-shadow: none !important;
}
.signMidellContainer .byDefaultCode:hover,.signMidellContainer .byDefaultCode:focus{
  border: 1px solid rgb(189, 189, 189) !important;
}
.signMidellContainer label{
  font-size: 14px;
  color: rgb(102, 102, 102);
  margin-bottom: 6px;
}
.checkbox-container label { margin-bottom: 0;}
.checkbox-container {
  flex-direction: column;
  align-items: center;
}
.again-send-otp a {
  font-size: 14px;
  display: inline-block;
}
.otp-wrap { gap: 10px;}
.otp-wrap .form-control {
  width: 40px;
  height: 40px;
  box-shadow: none;
  padding: 10px;
  text-align: center;
}
.otp-container p {
  font-size: 15px;
  margin-top: 5px;
}
.signMidellContainer .inputPhpneForSignLog input{
   height: 40px;
   outline: none !important;
   font-size: 14px;
    border-radius: 8px ;
   color: rgb(102, 102, 102);
   border: 1px solid rgb(189, 189, 189);
   transition: all 0.3s ease;
}
.signMidellContainer .inputPhpneForSignLog input:hover,.signMidellContainer .inputPhpneForSignLog input:focus{
  border: 1px solid rgb(124, 124, 124);
}
.signMidellContainer .singingButton {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 14px;
  border: none;
  color: #fff;
  background: linear-gradient(90deg, rgba(203, 28, 30, 1) 32%, rgba(231, 44, 34, 1) 79%);
  background-size: 150% 150%; /* Allows animation movement */
  padding: 8px 20px;
  cursor: pointer;
  animation: gradientButtonAnimation 5s ease infinite; /* Add animation */
  text-align: center;
  border-radius: 8px; /* Optional, for rounded corners */
  transition: all 0.3s ease;
}
.signMidellContainer .singingButton:hover {
  background: linear-gradient(90deg, rgba(203, 28, 30, 1) 32%, rgba(231, 44, 34, 1) 79%);
}
@keyframes gradientButtonAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.signMidellContainer .alreadyHAveAnAccount{
  font-size: 14px;
  text-align: center;
  color: rgb(102, 102, 102);
}
.signMidellContainer .alreadyHAveAnAccount a{
  color: #E74829;
  transition: all 0.3s ease;
}
.signMidellContainer .alreadyHAveAnAccount a:hover{
  opacity: 0.6;
}


.signupCatagory .dropdown{
  display: block !important;
  position: relative !important;
}

.signupCatagory .dropdown.show{
  display: block !important;
  position: relative !important;
}
.signupCatagory .dropdown-menu.show {
    right: auto;
    position: relative;
}
.signupCatagory .dropdown .dropdown-menu{
  border-radius: 10px;
  box-shadow: none;
  background: #FCFCFC;
  /* border: 1px solid rgb(245, 245, 245); */
  overflow: hidden;
  /* box-shadow: 0px 0px 5px 0px rgba(179,179,179,0.3) !important; */
}
.signupCatagory .dropdown-menu .dropdown-item:nth-child(1){
  font-size: 16px;
  font-weight: 500;
  padding-top: 18px;
  padding-bottom: 15px;
  color: #333;
  position: relative;
  text-align: center;
  text-transform: uppercase;
 border-top: none;
 transition: all 0.3s ease;
 /* background: linear-gradient(90deg, rgba(203, 28, 30, 0.8) 32%, rgba(231, 44, 34, 0.4) 79%) !important;
 margin: -1px -1px 0 -1px; */
 width: auto;
}

.signupCatagory .dropdown-menu .dropdown-item:nth-child(1):hover{
  /* background: linear-gradient(90deg, rgba(203, 28, 30, 1) 32%, rgba(231, 44, 34, 1) 79%);
  color:#fff */
  background: none;
}

.signupCatagory .dropdown-menu .dropdown-item:not(:first-child) {
 font-size: 14px;
 padding-top: 8px;
 border-top: 1px solid rgb(243, 243, 243);
 padding-bottom: 8px;
}
.signupCatagory .dropdown-menu .dropdown-item:not(:first-child):focus{
  background: linear-gradient(90deg, rgba(203, 28, 30, 1) 32%, rgba(231, 44, 34, 1) 79%);
  color:#fff
}
.signupCatagory .animationsetup{
 width: fit-content;
 position: relative;
 display: flex;
 align-items: center;
 gap: 7px;
}
.signMidellContainer,
.signupCatagory {
  /* border: 1px solid rgba(176,167,176,0.5); */
  /* border-radius: 10px;
  background-color:#FCFCFC;
  box-shadow: 0px 0px 5px 0px rgba(179,179,179,0.3) !important;
  min-height: 30vw; */
}
.boxCenter {
  max-width: 500px;
  margin: 0 auto;
  /* border: 1px solid rgba(176,167,176,0.5); */
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgba(179,179,179,0.3) !important;
}
.or {
  text-align: center;
  position: relative;
}
.or::after {
  content: '';
  height: 1px;
  width: 100%;
  background-color: #ccc;
  position: absolute;
  top: 12px;
  left: 0;
  z-index: 1;
}
.or span {
  display: inline-block;
  padding: 0 10px;
  background-color:#fff;
  font-size: 13px;
  position: relative;
  z-index: 2;
}
.form-group { margin-bottom: 15px;}
.clockwise {
  cursor: pointer;
}
.clockwise-wrap {
  gap: 15px;
  align-items: center;
}
.form-group .form-control {
  box-shadow: none;
}



/* Animations */
@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

/* Classes for animation */
.slide-in-right {
  animation: slideInRight 0.5s forwards;
}

.slide-out-right {
  animation: slideOutRight 0.5s forwards;
}

.slide-in-left {
  animation: slideInLeft 0.5s forwards;
}

.slide-out-left {
  animation: slideOutLeft 0.5s forwards;
}
.signMidellContainer {
  overflow: hidden;
}
.boxCenter.slide-out-left,
.boxCenter.slide-out-right { display: none;}
.boxCenter.slide-in-right {display: block;}

.inputPhpneForSignLog .col-6 {
  width: calc(calc(100% - 15px) / 2);
}
.clockwise-left { left: -6px; position: relative;}
.gap-15 { gap: 15px;}
.checkbox-container label a {
  color: #E74829;
}