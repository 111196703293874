.brand {
    width: 100%;
    overflow: hidden;
    /* Prevent x-axis overflow within the hero section */
}
.brand .brandSlide{
    position: relative;
    overflow: hidden;
}
.brand .card,.brand .card a{
    width: 100%;
    height: 130px;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
}
.brand .card{
    border: 1px solid #e7e7e7;
    border-radius: 0px;
}
.brand .card img{
    width: 50%;
    height: 50%;
}