.productComare{
    margin-top: 30px;
    overflow: auto;
 }
 .productComare .compairContainer{
     padding: 16px;
     overflow-x: auto;
     margin: 0 auto;
     max-width: 100%;
     width: 100%;
     display: flex
 ;
     align-items: center;
     flex-direction: column;
     justify-content: center;
     
 }
 .productComare .compairConProduct{
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     align-items: center;
     justify-content: center;
    
     width: 1100px;
 
 }
 .productComparenavbar .compareCencelButton button{
    display: none;
 }
 .productComparenavbar .showMoreCompare{
    font-size: 12px;
    text-decoration: none;
    background: rgb(246, 140, 30) ;
    color: #fff;
    transition: all 0.3s ease;   
    padding: 5px 8px;
 }
 .productComparenavbar .showMoreCompare:hover{
     opacity: 0.6;
 }
 
 .productComare .productName h3 {
     font-size: 14px !important;
    
     -webkit-line-clamp: 1;
 }
 .productComare .originalPrice h4{
 
 }
 .productComare .productPrize h4 {
    
     font-size: 14px;
 }
 .productComare .productPrize .originalPrice  del{
     font-size: 12px !important;
 }
 .productComare .productCaption {
  
     padding: 0px 0px;
 }
 .removeCompareBtn{
     position: absolute;
     top: 10px;
     display: flex;
     align-items: center;
     justify-content: center;
     width: 28px;
     height: 28px;
     outline: none;
     border-radius: 100px;
     background: #fafafa;
     border: 1px solid rgb(235, 235, 235);
     color: var(--CommonColor);
     font-size: 14px;
     right: 5px;
     font-size: "10px";
     transition: all 0.3s ease;
 }
 .removeCompareBtn:hover{
     background: var(--CommonColor);
     color: #fff;
 }
 
 .compairConProduct .compareCencelButton{
     width: 200px;
     padding: 10px;
    
     border-right: 0px solid red;
 }
 .compairConProduct .compareCencelButton h3{
     font-size: 16px;
     font-weight: 600;
     margin-bottom: 5px;
 }
 .compairConProduct .compareCencelButton p{
     font-size: 13px;
     color: var(--GrayColor);
 }
 .compairConProduct .compareCencelButton button{
     margin-top: 10px;
     border-radius: 0px;
     font-size: 14px;
     background: var(--CommonColor) !important;
     border: none;
     outline: none;
     transition: all 0.3s ease;
 }
 .compairConProduct .compareCencelButton button:hover{
     opacity: 0.6;
 }
 .noProductCompare{
     width: 100%;
     height: 80vh;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
 }
 .noProductCompare img{
     width: 200px;
     height: 200px;
 }
 .noProductCompare p{
     font-size: 14px;
     color: var(--GrayColor);
 }
 .noProductCompare p a{
     color: rgb(85, 76, 138);
     transition: all 0.3s ease;
 }
 .noProductCompare p a:hover{
     opacity: 0.6;
 }
 
 .compairConProduct .mainProductCart{
     max-width: 225px !important;
     width: 225px !important;
     flex: none;
     height: 325px;
     margin-bottom: 0px !important;
     border-left:1px solid rgb(240, 240, 240);;
 }
 .compairConProduct .mainProductCart:last-child{
     border-right:1px solid rgb(240, 240, 240);;
 }
 .compairConProduct .row{
     display: flex;
     padding: 0px;
     margin: 0px;
     flex-wrap: nowrap;
     /* width: calc(900px -225px); */
     /* max-width:  calc(900px -225px); */
     overflow: hidden;
 }
 .productComare table{
     /* width: 1100px; */
    /* width: 877px; */
    /* width: 651px; */
    /*     width: 430px; */
 }
 .productComare table tr th:nth-child(1),.productComare table tr td:nth-child(1){
     width: 200px ;
    
 }
 .productComare table tr th{
      font-size: 14px;
     font-weight: 500;
 }
 
 .productComare table tr td,.productComare table tr th{
     width: 225px;
     font-size: 13px;
     border: 1px solid rgb(240, 240, 240);
 }
 .productComparenavbar .productComare table {
     width: 650px !important;
    
 }
 .productComparenavbar .productComare table.compareTabsFor{
     width: 427px !important;
 }
 .productComparenavbar .productComare .compairConProduct{
     width: 650px !important;
 }
 @media screen and (max-width:1300px) {
   .productdetails-mainslider  .productComare .compairContainer{
         align-items: start;
     flex-direction: column;
     justify-content: start;
     }
 }
 @media screen and (max-width:1100px) {
 
 /* .productComare{
    
     overflow-x:auto ;
 } */
 .productComare .compairContainer {
     padding: 16px;
     overflow-x: auto;
     align-items: start;
 }
 .productComare .compairConProduct {
     /* width: calc(1100px - 225px); */
     align-items: center;
     justify-content: start;
 }
 
 }