.weekdealBgImage{
    width: 100%;
    height: 450px;
    position: absolute;
    margin-top: 100px;
}
.weekdealBgImage img{
    width: 100%;
    height: 450px;
}
.weekdealBgImage::after{
    content: '';
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}


.weekdealbuttonshow{
    z-index: 99;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    background: var(--CommonColor);
    width: 300px;
    height: 60px;
    margin-top: 20px;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    transition: all 0.3s ease;
  }
  .weekdealbuttonshow:hover{
    opacity: 0.5;
    background: var(--CommonColor);
  }
.sdnafsdn{
    background: #FCFCFC;
    padding: 20px 10px;
}  