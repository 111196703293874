.ask-question-form {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}
.ask-question-form label {
    font-weight: 500;
    color: #495057;
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
  .ask-question-form .form-check-label{
    font-size: 12px;
  }
  .ask-question-form .form-control {
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    padding: 0.4rem 0.5rem;
    font-size: 13px;
    box-shadow: none;
  }
  .ask-question-form .form-control:hover, .ask-question-form .form-control:focus{
    border: 1px solid #9fc1e9;
  }
  .ask-question-form  textarea.form-control {
    resize: none;
  }

  .ask-question-form  button[type="submit"] {
    background: linear-gradient(90deg, rgba(203, 28, 30, 1) 32%, rgba(231, 44, 34, 1) 79%);
    color: #fff;
    padding: 0.4rem 1.5rem;
    border: none;
    font-size: 14px;
    margin-top: 0px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}
.faqeslist{
    margin-bottom: 10px;
}
.faqeslist .accordion-item{
    border-color: #efefef;
}
.faqeslist .accordion-button{
  font-size: 13px;
  display: flex;
  position: relative;
  border: none;
  font-weight: 500;
  overflow: hidden;
  color: #262727 !important;
  flex-direction: column;
  align-items: baseline;
  padding: 8px 0px 8px 10px;
  box-shadow: none !important;
}
.faqeslist .accordion-button:not(.collapsed){
    background: #f1f2f3;
}
.faqeslist .accordion-body{
    padding: 10px;
    font-size: 13px;
    color: #262727;
}
.faqeslist .accordion-button p{
    padding-right: 32px;
    text-align: justify;
    
}
.faqeslist .accordion-button p:nth-child(2){
    font-size: 10px;
    color: #6c757d;
    font-weight: 600;
    margin-top: 2px;
}
.faqeslist  .accordion-button::after {
    top: 43%;
    margin-top: -3px;
    font-size: 10px;
    right: 3px;
    width:1rem !important;
    position: absolute;
    filter: invert(22);
    background-size:1rem;
}
.faqeslist  .accordion-button:not(.collapsed)::after{
    
}
.faqeslist  .accordion-button::before{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    background: #cb1c1f96;
    width: 23px;
}
.form-check-input:checked[type=checkbox]{
    box-shadow: none !important;
}