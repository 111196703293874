.allProductList {
    position: relative;
}
.allProductList .nav-tabs{
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.allProductList .nav-tabs .nav-link{
    border: none;
    background: none;
    border-radius: 0px;
    padding: 2px 8px;
    font-size: 13px;
    font-weight: 600;
    color: var(--GrayColor);

}
.allProductList .nav-tabs .nav-link.active{
    background:none;
    border: none;
    border-radius: 0px;
    padding: 2px 8px;
    color: var(--CommonColor);
}


.allProductList .allProductListproductCard .col-md-3{
    margin-bottom: 20px;
}

.allProductList .allProductListproductCard .productCard{
    border: 1px solid #e7e7e7;
}
.allProductList .nav-tabs{
    position: absolute;
    top: -55px;
    right: 200px;

}