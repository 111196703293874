


.userAccoundDetails{
    box-shadow: 0px 0px 5px 0px rgba(179, 179, 179, 0.3) ;
    margin-top: 20px;
    padding: 20px;
    border-radius: 10px;
}
.userAccoundDetails .infutUserEditSave input{
    border-radius: 4px 0px 0px 4px !important;
    border-right: none !important;
    transition: all 0.3s ease;
}
input[readonly]{
    color:rgb(138, 138, 138);
    background:#f5f5f5
}
input[readonly]:hover{
    border: 1px solid #e7e7e7 !important;
    border-right: none !important;
}
input[readonly]:focus{
    color:rgb(138, 138, 138);
    background:#f5f5f5
}
.userAccoundDetails .infutUserEditSave button{
    background: rgb(246, 140, 30) !important;
    color: #fff;
    width: 50px;
    border-radius: 0px 4px 4px 0px !important;
    border: 1px solid rgb(246, 140, 30);
   box-shadow: none;
   outline: none !important;
   transition: all 0.3s ease;
}
.userAccoundDetails .infutUserEditSave button:hover{
    opacity: 0.7;
}

.userAccoundDetails .infutUserEditSave .saveProfileBtn{
    background: rgb(74, 177, 105) !important;
    border: 1px solid rgb(74, 177, 105);
}


.action-buttons{
    margin-top: 20px;
    display: flex;
    gap: 5px;
}

.action-buttons button{
    box-shadow: none;
    border: none;
    outline: none;
    padding: 2px 10px;
    transition: all 0.3s ease;
    border-radius: 2px; color: #fff;
}
.action-buttons button:nth-child(1),.action-buttons button:nth-child(2){
    background:rgb(246, 140, 30) !important ;
}
.action-buttons button:nth-child(3){
background: rgba(203, 28, 30, 1);
}
.action-buttons button:hover{
    opacity: 0.7;
}

.profileuserr{
   box-shadow: 0px 0px 5px 0px rgba(179, 179, 179, 0.3);
   padding: 20px 15px;
   border-radius: 10px;
}

.userInformation{
    text-align: center;
}
.userImage img{
    width: 70px;
    height: 70px;
    border: 1px solid #d8d8d8;
    border-radius: 100px;
    background-color: rgb(250, 250, 250);
}
.useraddress{
    margin-top: 10px;
}
.useraddress p:nth-child(1){
   font-size: 18px;
   font-weight: 600;
}
.useraddress p:nth-child(2),.useraddress p:nth-child(3){
    font-size: 16px;
    color:var(--GrayColor)
}
.userHistory{
    margin-top: 20px;
}
.userHistory ul{
    padding: 0;
    margin: 0;

}
.userHistory ul li{
    padding: 0px;
    list-style-type: none;
    margin: 0px;
    width: 100%;
}

.userHistory .nav-pills .nav-link{
    width: 100%;
    display: block;
    padding: 9px 8px;
    text-decoration: none;
    border-top: 1px solid #ececec;
    color: var(--GrayColor);
    font-size: 14px;
    transition: all 0.3s ease;
}
.userHistory ul li a:hover{
    background: rgb(245, 245, 245);
    font-weight: 500;
}
.userHistory ul li:last-child a{
    border-bottom: 1px solid #ececec;
}

.userHistory .nav-pills .nav-link.active,.userHistory .nav-pills .show>.nav-link {
    background: rgb(245, 245, 245);
    font-weight: 500;
}
