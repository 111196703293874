


/* .productimageslick .slider {
    height: 100px !important;
} */
/* CustomPaging.css */
.productdetails-mainslider .slick-slider {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row-reverse;
    gap: 10px;
  }
  
  .productdetails-mainslider .slick-list {
   
    width: 100%; /* Main image container width */
  }
  
  .productdetails-mainslider .slick-dots {
    display: flex !important;
    flex-direction: column;
    position: static !important;
   width: fit-content;
    justify-content: flex-start;
    /* align-items: center; */
    justify-content: left;
    padding-left: 0px;
    gap: 10px;
  }
  
  .productdetails-mainslider .slick-dots li {
    margin: 0 0 0px 0; /* Spacing between thumbnails */
  }
  
  .productdetails-mainslider .slick-dots img {
    height: 55px !important;
    width: 55px;
    object-fit: fill;
    border-radius: 4px;
    cursor: pointer;
  }
  .productdetails-mainslider .slick-dots li{
   width: 55px;
   object-fit: fill;
    height: 55px !important;
   overflow: hidden;
   border-radius: 4px;
   transition: all 0.3s ease;
   border: 1px solid rgb(233, 233, 233);
   box-shadow: 0px 0px 3px 0px rgb(250, 250, 250);
  }
  .productdetails-mainslider .slick-dots li.slick-active{
    border: 1px solid rgb(190, 190, 190);
    outline: none !important;
    box-shadow: none;
    border-radius: 4px;
    box-shadow: 0px 0px 3px 0px rgb(190, 190, 190);;
  } 

  .productdetails-mainslider .slick-dots li a{
      display: block
  }
  .productdetails-mainslider .slick-dots li.slick-active img {
   
  }
  .productdetails-mainslider .productdetails-mainslider-main-image{
    width: 100%;
    height:100%;
   
    object-fit: fill;
  }
  .productdetails-mainslider .slick-slide{
    height: 450px;
    background: rgb(250, 250, 250);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .productdetails-mainslider .slick-slide div{
    object-fit: fill;
    height: 100%;
    width: 100% !important;
  }
  .productdetails-mainslider .slick-prev{
    margin-left: 65px;
  }
  .productdetails-mainslider .productdetails-mainslider-main-image {
    transition: transform 0.5s ease;
  }
  
  .productdetails-mainslider .productdetails-mainslider-main-image:hover {
    transform: scale(1.5); /* This will zoom the image */
  }

.slider img {
    width: 100%;
    height: 400px;
}
.slider-container {
    width: 100%;
    max-width: 100%;
    position: relative;
    /* Ensure positioning context for arrows */
    padding: 0 0px;
    /* Adjust padding to accommodate arrows */
}

/* Custom CSS for slider arrows */
.slick-prev,
.slick-next {
    width: 40px;
    height: 40px;
    z-index: 1;
    top: 50%;
    /* Center vertically */
    transform: translateY(-50%);
    /* Center vertically */
    position: absolute;
    /* Position arrows absolutely within the container */
}

.slick-prev {
    left: 10px;
    /* Adjust as needed */
}

.slick-next {
    right: 10px;
    /* Adjust as needed */
}
.slick-next,.slick-prev{
    background:none !important;
    border: 1px solid var(--GrayColorBtn);
    font-size: 7px;
    width: 32px !important;
    height: 32px !important;
    border-radius: 4px;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 18px -4px rgba(143, 143, 143, 0.5);
}
.slick-next:hover,.slick-prev:hover{
  border:1px solid var(--CommonColor) ;
}
.slick-prev:before,
.slick-next:before {
    font-size: 40px;
    color: black;
    /* Change color if needed */
}
.slick-next:before{
    content: '';
    background: url("../../public/imgs/common-icon/angle-right.svg") no-repeat;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 17px;
    height: 15px;
    position: absolute;
    background-size: 15px;
}
.slick-prev:before{
    content: '';
    background: url("../../public/imgs/common-icon/angle-left.svg") no-repeat;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 17px;
    height: 15px;
    position: absolute;
    background-size: 15px;
}

.slick-prev:hover:before, .slick-next:hover:before {
  filter: invert(17%) sepia(93%) saturate(2803%) hue-rotate(345deg) brightness(90%) contrast(100%);
}