.quantity_controls_viewcard{
  width: 65px;
}
.quantity_controls_viewcard button{
  background: rgb(246, 140, 30);
  color: #fff;
  outline: none;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  height: 22px;
  outline: none;
  opacity: 1;
  border: none;
  box-shadow: none;
  border-radius: 100px;
  transition: all 0.3s ease;
}
.quantity_controls_viewcard button:hover{
  opacity: 0.7;
}
.checkoutBox {
  padding: 20px;
  /* border: 1px solid rgba(176, 167, 176, 0.5); */
  border-radius: 10px;
  background-color:#ffffff;
  box-shadow: -1px 0px 10px 1px rgba(179, 179, 179, 0.2); 
  margin-bottom: 25px;
}

.checkoutBox table tr th{
  font-size: 13px;
  font-weight: 600;
  color: var(--GrayColor);

}
.checkoutBox table tr td{
  font-size: 13px;
  font-weight: 500;
  color: var(--GrayColor);
}
.checkoutBox h4 { 
  font-size: 17px;
  font-weight: 600;
}

.checkoutBox .removeProductCart {
 font-size: 16px;
 padding: 0px;
 background: var(--CommonColor);
 width: 30px;
 height: 30px;
 display: flex;
 align-items: center;
 justify-content: center;
 transition: all 0.3s ease;
}
.checkoutBox .removeProductCart:hover{
  opacity: 0.7;
}
.checkoutBox .empty-cart {
  background: var(--CommonColor);
  border: none;
  color: #fff;
  padding: 5px 20px;
  transition: all 0.3s ease;
}
.checkoutBox .empty-cart:hover{
  opacity: 0.7;
}




.order-card {
  /* border: 1px solid #ccc; */
  padding: 20px;
  background-color: #FCFCFC;
  border-radius: 10px;
  box-shadow: -1px 0px 10px 1px rgba(179, 179, 179, 0.2); 
}
.order-card h5 {
  border: none;
  padding: 5px;
  border-radius: 3px;
  line-height: 1.2;
  margin: 0 0 10px 0;
  font-size: 17px;
  font-weight: 600;
}
.order-card  span{
  color: var(--GrayColor);
  font-size: 13px;
  font-weight: 500;
}
.order-card  strong{
  font-size: 13px;
}

.total-view {
  border-top: 1px solid #d4d4d4;
  margin-top: 8px;
  padding-bottom: 10px;
}
.order-card .btn-dark{
  background: rgb(246, 140, 30);
  padding: 4px 10px;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  transition: all 0.3s ease;
}
.order-card .btn-dark:hover{
  opacity: 0.7;
}

.moreontinueShopping h6{
  background: #e9e9e9;
  margin: 0px;
 
  border-radius: 4px;
  text-align: center;
  padding: 8px 5px;
  font-size: 13px;
  position: relative;
}
.moreontinueShopping h6 a{
  text-decoration: none;
  width: 100%; color: #666666;
  height: 100%;
  display: block;
  transition: all 0.3s ease;
}
.moreontinueShopping h6 a:hover{
  opacity: 0.7;
}