.specialOfferCard{
    /* background-image: url('../../public/imgs/spcialoffer/1.jpg'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.specialOfferCard .card{
    border: none;
    border-radius: 0px;
    background: none;
}
.specialOfferCard .card h3{
    color: var(--WhiteColor);
    font-size: 28px;
    font-weight: 600;
}
.specialOfferCard .card a{
    border: 1.5px solid var(--WhiteColor);
    box-shadow: none;
    outline: none;
    border-radius: 0px;
    background: transparent;
    color: var(--WhiteColor);
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 0.3s ease;
}
.specialOfferCard .card a:hover,.specialOfferCard .card a:focus{
    border: 1.5px solid var(--CommonColor);
    color: var(--WhiteColor);
    background: var(--CommonColor);
}
.specialOfferCard .card .specialOffercardDes{
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}




@-webkit-keyframes introduceBadge {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes introduceBadge {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes pulseBadge {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes pulseBadge {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  @-webkit-keyframes pulseBadge2 {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes pulseBadge2 {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  .badge {
    -webkit-animation: introduceBadge 1s linear 0s 1 both;
            animation: introduceBadge 1s linear 0s 1 both;
    background: rgb(246,140,30);
    border-radius: 50%;
    height: 100px;
    perspective: 600px;
    position: absolute;
    left: 10px;
    top: 10px;
    width: 100px;
    
  }
  .badge:before, .badge:after {
    -webkit-animation: pulseBadge 3s cubic-bezier(0.86, 0, 0.07, 1) 0s infinite both;
            animation: pulseBadge 3s cubic-bezier(0.86, 0, 0.07, 1) 0s infinite both;
    border: 3px solid rgba(203, 28, 30, 1);
    border-radius: inherit;
    bottom: -16px;
    content: "";
    left: -16px;
    opacity: 0.5;
    position: absolute;
    right: -16px;
    top: -16px;
  }
  .badge:after {
    -webkit-animation-name: pulseBadge2;
            animation-name: pulseBadge2;
    bottom: -32px;
    left: -32px;
    opacity: 0.4;
    right: -32px;
    top: -32px;
  }
  
  @-webkit-keyframes introduceLabel {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.4) rotateY(-1800deg);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1) rotateY(20deg);
    }
  }
  
  @keyframes introduceLabel {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.4) rotateY(-1800deg);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1) rotateY(20deg);
    }
  }
  @-webkit-keyframes rotateLabel {
    0% {
      transform: translate(-50%, -50%) rotateY(20deg);
    }
    50% {
      transform: translate(-50%, -50%) rotateY(-20deg);
    }
    100% {
      transform: translate(-50%, -50%) rotateY(20deg);
    }
  }
  @keyframes rotateLabel {
    0% {
      transform: translate(-50%, -50%) rotateY(20deg);
    }
    50% {
      transform: translate(-50%, -50%) rotateY(-20deg);
    }
    100% {
      transform: translate(-50%, -50%) rotateY(20deg);
    }
  }
  .badge__label {
    -webkit-animation: introduceLabel 2s cubic-bezier(0.19, 1, 0.22, 1) 1s 1 both, rotateLabel 5s linear 3s infinite;
            animation: introduceLabel 2s cubic-bezier(0.19, 1, 0.22, 1) 1s 1 both, rotateLabel 5s linear 3s infinite;
    color: #ffff;
    font-size: 26px;
    left: 50%;
    position: absolute;
    text-align: center;
    text-shadow: 0px 4px 8px rgba(0, 113, 246, 0.2);
    top: 50%;
    transform: translate(-50%, -50%);
  }